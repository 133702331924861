import * as React from 'react';
import { useParams } from 'react-router-dom';
import { LoadingScreen } from '../../components/atoms/Defaults';
import { Navigation } from '../../components/organisms/Navigation';
import { FeedTemplate } from '../../components/templates/FeedTemplate';
import Layout from '../../components/templates/Layout';
import { Loading, LoadingState } from '../../globals/states';
import { KTNFeedType } from '../../../../gogoo-common/content/Kultunaut'
import apiHooksV2 from '../../globals/api-hooks-v2';
import { Feed, Organisation } from 'gogoo-common/content/Organisation';
import { BreadCrumb } from '../../components/molecules/BreadCrumb';


export const FeedPage: React.FC<{ create?: boolean }> = ({ create }) => {
  const { organizationId, feedId } = useParams<{ organizationId: string, feedId?: string }>()
  const [organization, setOrganization] = React.useState<Organisation>()
  const [loading, setLoading] = React.useState<LoadingState>()
  const [feedData, setFeedData] = React.useState<Feed>()

  const init = React.useCallback(async () => {
    setLoading({ loading: Loading.LOADING })
    const result = organizationId && await apiHooksV2.getOrgansationsDashboard(organizationId)
    if (result) {
      setOrganization(result.organisation)
      const feed = !!feedId && result.organisation.feeds.find((feedItem) => feedItem.feedId === feedId) || false
      if (feed) setFeedData(feed)
      setLoading({ loading: Loading.COMPLETE })
    } else {
      setLoading({ loading: Loading.ERROR, errorMessage: "Kunne ikke finde organisationen med id: " + organizationId })
    }
  }, [organizationId, feedId])

  const onSubmit = async (feedName: string, feedString: string, feedType?: KTNFeedType) => {
    console.log({ feedName, feedString })
    if (!organization) return
    const tempOrganization = organization
    setLoading({ loading: Loading.LOADING })


    if (!tempOrganization.feeds) tempOrganization.feeds = []

    let existingFeed = organization.feeds?.find(feed => feed.feedId === feedId)
    if (existingFeed) {
      existingFeed.feedName = feedName;
      existingFeed.feedString = feedString;
      existingFeed.feedType = feedType;
    } else tempOrganization.feeds.push({ feedName, feedString, feedType, feedId: "" })

    const result = await apiHooksV2.updateOrganisation(tempOrganization)
    if (result) {
      setLoading({ loading: Loading.COMPLETE })
    } else {
      setLoading({ loading: Loading.ERROR, errorMessage: "Der skete en fejl i kommunikationene til serveren" })
    }
  }

  React.useEffect(() => { init() }, [init])

  if (!loading || loading.loading === Loading.LOADING) return <LoadingScreen />
  if (loading.loading === Loading.ERROR) return <p>Error: {loading.errorMessage}</p>
  if (!organization) return <p>Error: Contact IT Admin</p>


  return (
    <Layout.Body>
      <Navigation title={organization?.name} />
      <Layout.Container>
        <Layout.HorizontalFlex style={{ justifyContent: 'space-between', marginLeft: 14, marginRight: 14, alignItems: 'center' }}>
          <BreadCrumb links={[
            { label: "Organisationer", url: "/organisations" },
            { label: organization.name, url: "/organisations/" + organization.organizationId },
            { label: "Feeds",url: "/organisations/" + organization.organizationId },
            { label: feedData?.feedName || "Nyt feed", url: "/organisations/" + organization.organizationId },
          ]} />
        </Layout.HorizontalFlex>
      </Layout.Container>
      <Layout.Container>
        <FeedTemplate onSubmit={onSubmit} feedData={feedData} 
        />
      </Layout.Container>
    </Layout.Body>
  )
}