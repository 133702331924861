import * as React from 'react';
import { colors } from '../../globals/colors';
import Logo from '../../assets/gogoologo.svg'
import Layout from '../templates/Layout'
import UserSettingMenu from '../molecules/UserSettingMenu';

export const Navigation: React.FC<{ title?: string }> = ({ title }) => {

  React.useEffect(() => {
    document.title = title ? (title + " | gogo admin panel") : "gogo admin panel"
  }, [title])


  return (
    <Layout.HorizontalFlexAlignCenter style={{
      display: 'flex', height: 50, backgroundColor: colors.background,
      paddingLeft: 10, paddingRight: 10, paddingTop: 4, paddingBottom: 4,
      justifyContent: 'space-between', marginBottom: 30
    }}>
      <a href='/' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textDecoration: "none" }}>
        <img src={Logo} height={35} alt={'logo'} />
        <b style={{ color: colors.white, textDecoration: "none", fontSize: 26, marginBottom: 3, marginLeft: 12 }}>Admin panel</b>
      </a>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <UserSettingMenu />
      </div>
    </Layout.HorizontalFlexAlignCenter>
  );
};

