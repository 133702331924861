import { API } from "aws-amplify"
import {
    AdminSegmentRequest,
    AdminSendNotificationToSegment,
    AdminSetOrganizationProductRequest,
    ApproveVenueAccess, ApproveOrganizationAccessRequest, BulkFile, BulkNotificationMetaInformation, AdminApiResponse,
    DetailedBanner,
    GetOrganizationDashboardResult, ManageBannerPublishProps, VenueAuthorizationTypes, VenueDashboard
} from "../shared/Admin"
import { BlogPost } from "../shared/BlogPotsts"
import { Banner } from "../shared/CMS"
import { VenuesRequest } from "../shared/GogoApi"
import { Organization, } from "../shared/Organization"
import { CreateQRCodeRequest, QRCode } from "../shared/QRCodes"
import { IncognitoUser } from "../shared/User"
import { AdminApi, ResourceUrl } from "./config"
import { UserMetaData } from "../shared/UserMetaData"
import { Product } from "../shared/Product"
import { DebugResponse, KTNFeedType, KultunautEvent, KultunautVenue, PremiumPackage, PremiumVenueMetaData } from "gogoo-common/content/Kultunaut"


const resetUser = async (): Promise<boolean> => {
    return await API.del(AdminApi, 'admin/reset', {})
}

const getOrganizationDashboard = async (id: string): Promise<GetOrganizationDashboardResult | undefined> => {
    const result = await API.get(AdminApi, 'admin/organizationDashboard/' + id, {})
    return result
}

const getOrganization = async (id: string): Promise<Organization | undefined> => {
    const result = await API.get(AdminApi, 'admin/organization/' + id, {})
    return result
}

const createOrganization = async (organization: Organization): Promise<AdminApiResponse | undefined> => {
    const result = await API.post(AdminApi, 'admin/organization', { body: organization })
    return result
}

const updateOrganization = async (organization: Organization): Promise<AdminApiResponse | undefined> => {
    const result = await API.put(AdminApi, 'admin/organization', { body: organization })
    return result
}

const getEvent = async (id: number): Promise<KultunautEvent> => {
    return await API.get(AdminApi, 'admin/event/' + id, {})
}

const getVenuesByIds = async (venueIds: number[]): Promise<KultunautVenue[]> => {
    const body: VenuesRequest = { venueIds }
    return await API.post(AdminApi, 'admin/venues', { body })
}

const getFeed = async (search: string, ktnFeed?: KTNFeedType): Promise<DebugResponse<KultunautEvent[]>> => {
    return await API.post(AdminApi, 'admin/feed', { body: { endpoint: search, ktnFeed } })
}

const getEventTags = async (): Promise<DebugResponse<string[]> | undefined> => {
    const result = API.post(AdminApi, 'admin/feed', { body: { endpoint: "EventTags" } })
    return result
}

const getBlogs = async (organizationId: string): Promise<BlogPost[]> => {
    const blogs = await API.get(AdminApi, 'admin/organization/blogs/' + organizationId, {})
    return blogs
}

const getBlogPostById = async (blogPostId: string): Promise<BlogPost> => {
    const blogs = await API.get(AdminApi, 'admin/blog/' + blogPostId, {})
    return blogs
}

const createBlogPost = async (blogPost: BlogPost): Promise<BlogPost | undefined> => {
    return await API.post(AdminApi, 'admin/blog', { body: blogPost })
}

const updateBlogPost = async (blogPost: BlogPost): Promise<BlogPost | undefined> => {
    return await API.put(AdminApi, 'admin/blog', { body: blogPost })
}

const requestAccessToOrganization = async (organizationId: string): Promise<boolean> => {
    const result = await API.post(AdminApi, 'admin/organizationrequest/' + organizationId, {})
    return !!result
}

const approveAccessToOrganization = async (body: ApproveOrganizationAccessRequest): Promise<boolean> => {
    const result = await API.post(AdminApi, 'admin/approveorganizationrequest', { body })
    return !!result
}
const uploadFile = async (file: File): Promise<string | undefined> => {
    try {
        const item = await API.put(AdminApi, 'admin/upload', {})
        await fetch(item.uploadUrl, {
            method: 'PUT',
            body: file
        })
        return ResourceUrl + item.key
    } catch (error) {
        return undefined
    }
}


const getUsersBySegment = async (body?: AdminSegmentRequest): Promise<IncognitoUser[] | undefined> => {
    return await API.post(AdminApi, 'admin/usersegment', { body })
}

const sendSegmentNotificaiton = async (body?: AdminSendNotificationToSegment): Promise<boolean | undefined> => {
    return await API.post(AdminApi, 'admin/sendusersegment', { body })
}

const getQRCodeById = async (id: string): Promise<QRCode> => {
    return await API.get(AdminApi, 'qrcode/' + id, {})
}

const createQRCode = async (request: CreateQRCodeRequest): Promise<QRCode> => {
    return await API.post(AdminApi, 'admin/qrcodes/', { body: request })
}

const listBulkNotificationsFiles = async (): Promise<BulkNotificationMetaInformation[] | undefined> => {
    return await API.get(AdminApi, 'admin/bulkfiles', {})
}
const getBulkNotificationsFile = async (bulkFileById: string): Promise<BulkFile | undefined> => {
    return await API.get(AdminApi, 'admin/bulkfiles/' + bulkFileById, {})
}
export type KultunautVenueResult = string[]; // [id, title, address]
export type KultunautSearchVenues = {
    inputPhrase: string;
    items: KultunautVenueResult[];
}
export async function searchVenues(phrase: string): Promise<KultunautSearchVenues> {
    const url = 'https://www.kultunaut.dk/perl/service/type-nynaut/places.json?phrase=' + phrase
    return await (await fetch(encodeURI(url), {})).json()
}


const getOrgainzationBanners = async (organizationId: string): Promise<Banner[] | undefined> => {
    return await API.get(AdminApi, 'admin/organization/banner/' + organizationId, {})
}

const getBannerById = async (bannerId: string): Promise<DetailedBanner | undefined> => {
    return await API.get(AdminApi, 'admin/banner/' + bannerId, {})
}

const createBanner = async (banner: Banner): Promise<Banner | undefined> => {
    return await API.post(AdminApi, 'admin/organization/banner', { body: banner })
}

const updateBanner = async (banner: Banner): Promise<Banner | undefined> => {
    return await API.put(AdminApi, 'admin/organization/banner', { body: banner })
}

const publishBanner = async (body: ManageBannerPublishProps): Promise<Banner | undefined> => {
    return await API.post(AdminApi, 'admin/organization/banner/publish', { body })
}

const getUserMetaData = async (userId: string): Promise<UserMetaData> => {
    const blogs = await API.get(AdminApi, 'admin/usermetadata/' + userId, {})
    return blogs
}

const setOrganizationProduct = async (request: AdminSetOrganizationProductRequest): Promise<boolean | undefined> => {
    const result = await API.post(AdminApi, 'admin/product', { body: request }) as boolean
    return result
}

const requestAccessToVenue = async (venueId: number): Promise<boolean | undefined> => {
    const result = await API.post(AdminApi, 'admin/venue/request/' + venueId, {}) as boolean
    return result
}

const getVenueDashboard = async (): Promise<VenueDashboard | undefined> => {
    const result = await API.get(AdminApi, 'admin/venue/dashboard', {}) as VenueDashboard
    return result
}

const updateVenue = async (premium: PremiumVenueMetaData): Promise<VenueDashboard | undefined> => {
    const result = await API.post(AdminApi, 'admin/premium/venue', { body: premium }) as VenueDashboard
    return result
}

const updatePackage = async (venueId: number, packageCode: string): Promise<PremiumVenueMetaData | undefined> => {
    const premiumPackage = packageCode as PremiumPackage
    const metaData: PremiumVenueMetaData = { premiumPackage, venueId, htmlContent: "", premiumValidUntil: "", isValid: true }
    const result = await API.post(AdminApi, 'admin/premium/venue', { body: metaData }) as PremiumVenueMetaData
    return result
}

const approveVenueAccess = async (venueId: number, adminUserId: string): Promise<boolean | undefined> => {
    const approveVenueAccess: ApproveVenueAccess = { venueId, adminUserId, venueAuthorization: VenueAuthorizationTypes.VENUE_OWNER }
    const result = await API.post(AdminApi, 'admin/venue/access/approve', { body: approveVenueAccess }) as boolean
    return result
}

const updateGogooProduct = async (body: Product): Promise<AdminApiResponse | undefined> => {
    const result = await API.put(AdminApi, 'admin/products', { body: body })
    return result
}

const hooks = {
    getOrganization, getEvent, getBlogs, createOrganization,
    requestAccessToOrganization, updateOrganization, getOrganizationDashboard,
    approveAccessToOrganization, createBlogPost, uploadFile, updateBlogPost, getBlogPostById,
    getFeed, getEventTags, getVenuesByIds, searchVenues, getQRCodeById, createQRCode, resetUser,
    getUsersBySegment, sendSegmentNotificaiton, listBulkNotificationsFiles, getBulkNotificationsFile,
    getOrgainzationBanners, getBannerById, createBanner, updateBanner, publishBanner, getUserMetaData,
    setOrganizationProduct, requestAccessToVenue, getVenueDashboard, updateVenue, updatePackage,
    approveVenueAccess,
    updateGogooProduct
}

export default hooks