import * as React from 'react';
import Layout from '../templates/Layout';

import { Tooltip } from '../atoms/Tooltip';
import { colors } from '../../globals/colors';


enum FillOptions { FILLED, HALF_FILLED, NOT_FILLED }
export const Line: React.FC<{ fillOption: FillOptions }> = ({ fillOption }) => {

  if (fillOption === FillOptions.HALF_FILLED) return (
    <>
      <div style={{ width: '65%', height: 12, backgroundColor: colors.primary, borderTopLeftRadius: 20, borderBottomLeftRadius: 20 }} />
      <div style={{ width: '35%', height: 12, backgroundColor: colors.main, borderTopRightRadius: 20, borderBottomRightRadius: 20 }} />
    </>
  )

  return <div style={{ width: '100%', height: 12, backgroundColor: fillOption === FillOptions.FILLED ? colors.primary : colors.main, borderRadius: 20 }} />
}

export const Stepper: React.FC<{ steps: string[], activeIndex: number }> = ({ steps, activeIndex }) => {

  return (
    <Layout.HorizontalFlexAlignCenter style={{ width: '60%', justifyContent: 'center', marginLeft: "20%", marginRight: "20%" }}>
      {steps.map((step, index) => {
        const tmpActiveIndex = activeIndex+1
        const fillOption = index === tmpActiveIndex ? FillOptions.HALF_FILLED : ((index > tmpActiveIndex) ? FillOptions.NOT_FILLED : FillOptions.FILLED)
        return (<>
          {index > 0 && <Line key={index+steps.length} fillOption={fillOption} />}
          <Tooltip key={index} description={step} customLabel={(index + 1).toString()} />
        </>)
      })}
    </Layout.HorizontalFlexAlignCenter>
  )
}

