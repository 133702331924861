import Layout from "../templates/Layout"


export const StatusComponent: React.FC<{ active: boolean }> = ({ active }) => {
    const color = active ? "#7cff5b" : "#ff2f2f"
    return (
      <Layout.HorizontalFlex>
        <div style={{ height: 15, width: 15, backgroundColor: color, borderRadius: "50%", marginRight: 10, marginTop: 3 }}></div>
        {active ? "Aktiv" : "Inaktiv"}
      </Layout.HorizontalFlex>
    )
  }