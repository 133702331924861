import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingScreen } from '../../components/atoms/Defaults';
import { Navigation } from '../../components/organisms/Navigation';
import Layout from '../../components/templates/Layout';
import { Loading, LoadingState } from '../../globals/states';
import apiHooksV2 from '../../globals/api-hooks-v2';
import { Organisation } from 'gogoo-common/content/Organisation';
import { BreadCrumb } from '../../components/molecules/BreadCrumb';
import { Banner, CreateBanner } from 'gogoo-common/content/Banner';
import { BannerEditor } from '../../components/templates/EditBanner';


export const BannerPage: React.FC<{ create?: boolean }> = ({ create }) => {
  const navigation = useNavigate()
  const { organizationId, bannerId } = useParams<{ organizationId: string, bannerId?: string }>()
  const [organization, setOrganization] = React.useState<Organisation>()
  const [loading, setLoading] = React.useState<LoadingState>()
  const [banner, setBanner] = React.useState<Banner>()

  const init = React.useCallback(async () => {
    setLoading({ loading: Loading.LOADING })
    const result = organizationId && await apiHooksV2.getOrgansationsDashboard(organizationId)
    if (result) {
      setOrganization(result.organisation)
      if (create) {
        setLoading({ loading: Loading.COMPLETE })
      } else {
        const banner = result.banners.find((i) => i.id === bannerId)

        if (banner) {
          setBanner(banner)
          setLoading({ loading: Loading.COMPLETE })
        } else {
          setLoading({ loading: Loading.ERROR, errorMessage: "Kunne ikke finde banner med id: " + bannerId })
        }
      }
    } else {
      setLoading({ loading: Loading.ERROR, errorMessage: "Kunne ikke finde organisationen med id: " + organizationId })
    }
  }, [organizationId])

  React.useEffect(() => { init() }, [init])

  if (!loading || loading.loading === Loading.LOADING) return <LoadingScreen />
  if (loading.loading === Loading.ERROR) return <p>Error: {loading.errorMessage}</p>
  if (!organization) return <p>Error: Contact IT Admin</p>

  const onUpdate = async (props: CreateBanner) => {
    setLoading({ loading: Loading.LOADING })
    if (create) {
      const result = await apiHooksV2.createBanner(props)
      setBanner(result)
      navigation("/organisations/" + organization.organizationId + "/banners/" + result.id)
    }
    else if (banner) setBanner(await apiHooksV2.updateBanner({ ...banner, ...props }))
    setLoading({ loading: Loading.COMPLETE })

  }

  return (
    <Layout.Body>
      <Navigation title={organization?.name} />
      <Layout.Container>
        <Layout.HorizontalFlex style={{ justifyContent: 'space-between', marginLeft: 14, marginRight: 14, alignItems: 'center' }}>
          <BreadCrumb links={[
            { label: "Organisationer", url: "/organisations" },
            { label: organization.name, url: "/organisations/" + organization.organizationId },
            { label: "Banners", url: "/organisations/" + organization.organizationId },
            { label: banner?.title || "Ny banner", url: "/organisations/" + organization.organizationId + "/banners/" + (bannerId || "create") },
          ]} />
        </Layout.HorizontalFlex>
      </Layout.Container>
      <Layout.Container>
        <BannerEditor banner={banner} organizationId={organization.organizationId} onSubmit={onUpdate} />
      </Layout.Container>
    </Layout.Body>
  )
}