import * as React from 'react';
import styled from 'styled-components';
import { colors } from '../../globals/colors';
import hooks from '../../globals/hooks';
import Layout from '../templates/Layout';
import Icons from './Icons';
import apiHooksV2 from '../../globals/api-hooks-v2';

const DropDownContainer = styled("div")`
  width: 100%;
  margin: 0 auto;
`;

const DropDownHeader = styled("div")`
  margin-bottom: 0.1em;
  padding: 0.4em 20px 0.4em 20px;
  background: ${colors.lightMain};
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color:${colors.white};
`;

const DropDownListContainer = styled.div<{ isOpen: boolean, width: number }>`
  transition: opacity 0.2s linear;
  opacity: ${({ isOpen }) => isOpen ? 1 : 0};
  visibility: ${({ isOpen }) => isOpen ? "visible" : "hidden"};
  width: ${({ width }) => width - 20}px;
  position: absolute;
  z-index: 1;
  border: 1px solid ${colors.white};
  border-top-width: 0px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-left: 10px;
  margin-right: 10px;
`;

const DropDownList = styled("ul")`
  padding: 0;
  margin: 0;
  background: ${colors.lightMain};
  box-sizing: border-box;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color:${colors.white};
`;

const ListItem = styled("li")`
  list-style: none;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  &:nth-child(odd) {
    background-color: ${colors.main};
  }
  &:nth-child(even) {
    background-color: ${colors.lightMain};
  }
  &:hover {
    background-color: ${colors.white};
    color: ${colors.main};
    cursor: pointer;
  }
  &:last-child{
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

export type KeyDisplayNamePair = {
  displayName: string;
  key: string | number
}

const Select: React.FC<{ items: KeyDisplayNamePair[], onSelect(item: KeyDisplayNamePair): void, selected?: KeyDisplayNamePair, newLink?: string }> = ({ items, selected, onSelect, newLink }) => {
  const ref = React.useRef<any>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [width, setWidth] = React.useState(0);
  React.useLayoutEffect(() => {
    if (ref && ref.current && ref.current.offsetWidth) setWidth(ref.current.offsetWidth)
  }, [])


  const handleClick = (item: KeyDisplayNamePair) => () => {
    onSelect(item)
    setIsOpen(false)
  }

  return (
    <div onMouseLeave={() => setIsOpen(false)} >
      <DropDownContainer ref={ref}>
        <DropDownHeader onClick={() => setIsOpen(!isOpen)}>
          <span>{selected ? selected.displayName : "Vælge"}</span>
          <Icons.ArrowDownward />
        </DropDownHeader>
        <DropDownListContainer isOpen={isOpen} width={width}>
          <DropDownList>
            {items.map((item, index) => <ListItem key={index} onClick={handleClick(item)}>{item.displayName}</ListItem>)}

            {newLink && (
              <a href={newLink} target="_blank" rel="noreferrer" style={{ color: colors.white, textDecoration: 'none' }}>
                <ListItem>
                  <Icons.Add />
                  <span style={{ marginLeft: 10 }}>Tilføj ny</span>
                </ListItem>
              </a>
            )}

          </DropDownList>
        </DropDownListContainer>
      </DropDownContainer>
    </div>
  )
}

const MaxSize = 2000000

const ImageUpload: React.FC<{ onChange(val: string): void }> = ({ onChange }) => {
  const ref = React.useRef<any>();
  const [error, setError] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleClick = () => {
    if (ref && ref.current && ref.current.click) ref.current.click()
  }

  const handleChange = async (files: FileList | null) => {
    setError(undefined)
    if (!files || files.length === 0 || !files[0]) return setError("Vælge venligst en fil")
    const file = files[0]
    if (file.size > MaxSize) return setError("Filen må max være 2 mb")
    setLoading(true)
    const result = await apiHooksV2.uploadFile(file)
    if (result) onChange(result)
    else setError("Der skete en fejl")
    setLoading(false)
  }

  return (
    <Layout.HorizontalFlex style={{ height: 35 }}>
      {loading ? <span>Loading..</span> : <button onClick={handleClick}>Upload billede</button>}
      {error && <span style={{
        backgroundColor: colors.main, paddingLeft: 10, paddingRight: 10,
        paddingTop: 4, paddingBottom: 4, marginLeft: 10, borderRadius: 10
      }}>{error}</span>}
      <input ref={ref} type="file" accept="image/*" onChange={(e) => handleChange(e.target.files)} style={{ display: 'none' }} />
    </Layout.HorizontalFlex>
  )
}


const TogleSwitch: React.FC<{ onCLick(): void, value: boolean }> = ({ onCLick, value }) => {

  return (
    <label className="toggle-switch">
      <input type="checkbox" checked={value} onChange={onCLick} />
      <div className="toggle-switch-background">
        <div className="toggle-switch-handle"></div>
      </div>
    </label>

  )
}

const InputWithSuffix: React.FC<{ onChange(val: string): void, suffix: string, value?: number|string, type: React.HTMLInputTypeAttribute, customWidth?:number }> = ({ onChange, suffix, value, type, customWidth }) => {

  return (
    <div className="inputWrapper" style={{ padding: 0, margin: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
      <input value={value} type={type} style={{ background: 'transparet', borderWidth: 0, borderStyle: 'none', width: customWidth || 100 }} onChange={(e)=> onChange(e.target.value)} />
      <span style={{ paddingRight: 12, fontSize: 12 }}>{suffix}</span>
    </div>
  )
}

const exports = { Select, ImageUpload, TogleSwitch, InputWithSuffix }

export default exports;