import { AdminUser } from "gogoo-common/admin/AdminUser"


export enum OrganisationAuthorizationTypes {
    ORGANIZATION_ADMIN = 50,
    ORGANIZATION_READ_WRITE_MEMBER = 40,
    ORGANIZATION_READ_MEMBER = 20,
    ORGANIZATION_ACCESS_REQUESTED_MEMBER = 10,
    NONE = 0,
}

export enum Authorization {
    SUPER_ADMIN = 100,
    GOGOO_ADMIN = 90,
    UNAUTHORIZED = 0
}

export const IsGogooAdmin = (user: AdminUser): boolean => {
    return user.authorization >= Authorization.GOGOO_ADMIN
}
