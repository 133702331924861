export const IsDev = process.env.REACT_APP_API === "development"
type ApiEndpoint = {
    name: string,
    endpoint: string,
    region: string
}

type CognitoConfigs = {
    mandetorySignIn: boolean;
    region: string;
    userPoolId: string;
    identityPoolId: string;
    userPoolWebClientId: string;
}


type AppConfigs = {
    Auth: CognitoConfigs;
    API: {
        endpoints: ApiEndpoint[]
    }

}


export const AdminApi = "ADMIN-API";

const LocalApiDev = "http://localhost:3000/dev"
const LocalApiProd = "http://localhost:3000/prod"
//const DevApi = "https://7e9riw22u7.execute-api.eu-west-1.amazonaws.com/dev"
const ProdApi = "https://r3fcr9kblk.execute-api.eu-west-1.amazonaws.com/prod"


const ResourceUrlProd = "https://prod-attachments-bucket-gogoo-1.s3.eu-west-1.amazonaws.com/"
const ResourceUrlDev = "https://dev-attachments-bucket-gogoo-1.s3.eu-west-1.amazonaws.com/"

export const ResourceUrl = IsDev ? ResourceUrlDev : ResourceUrlProd

let endpoint = IsDev ? LocalApiDev : ProdApi
if (process.env.REACT_APP_API === "prod") endpoint = LocalApiProd


const prodConfig: AppConfigs = {
    Auth: {
        mandetorySignIn: true,
        region: "eu-west-1",
        userPoolId: "eu-west-1_y6FjJNcQk",
        identityPoolId: "eu-west-1:6ac4daa8-aa6c-4108-9e97-56f8fb4dc342",
        userPoolWebClientId: "7m1n9fg42vg1q8aho59951rnns"
    },
    API: {
        endpoints: [{
            name: AdminApi,
            endpoint: endpoint + '/',
            region: "eu-west-1"
        }]
    }
}

export const OAuthConfig = {
    domain: "gogoo-admin.auth.eu-west-1.amazoncognito.com",
    scope: [
        "phone",
        "email",
        "profile",
        "openid",
        "aws.cognito.signin.user.admin"
    ],
    redirectSignIn: IsDev ? "http://localhost:5000" : "https://www.admin.gogoo.app",
    redirectSignOut: IsDev ? "http://localhost:5000/logout" : "https://www.admin.gogoo.app/logout",
    responseType: "token"
}


export default prodConfig
