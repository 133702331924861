import * as React from 'react';
import Logo from '../../assets/gogoologo.svg'

export const LoadingScreen:React.FC = () => {

  return (
    <div style={{width: "100%", height: "100vh", display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
      <img alt="logo" className="rotate" src={Logo} height={50} />
      <span className="animate-loading">Loading</span>
    </div>
  );
}
