import * as React from 'react';
import Layout from '../templates/Layout';


export type RequestableItem = {
  name: string,
  id: string,
  status: string
}

export enum AccessVariant {
  ACCESS_GRANTED = "ACCESS_GRANTED", REQUEST_SEND = "REQUEST_SEND", OTHER = "OTHER"
}

type RequestableListRowProps = { item: RequestableItem, variant: AccessVariant, customRender?: (item: RequestableItem) => React.ReactNode, onClick: (item: RequestableItem) => Promise<void> }
const RequestableListRow: React.FC<RequestableListRowProps> = ({ item, onClick, variant, customRender }) => {
  const [loading, setLoading] = React.useState(false)
  const handleBoxClick = () => {
    if (onClick && variant === AccessVariant.ACCESS_GRANTED) onClick(item)
  }

  let label;
  if (variant === AccessVariant.ACCESS_GRANTED) {
    label = item.status
  }
  else if (variant === AccessVariant.REQUEST_SEND) label = "Anmodning sendt"

  const handleRequestAccess = async () => {
    setLoading(true)
    if (onClick) await onClick(item)
    window.location.reload()
    setLoading(false)
  }

  const requestAccessButton = loading ? <></> : <button onClick={handleRequestAccess}>Anmod om adgang</button>
  let toReturn: string | React.ReactNode = label ? label : requestAccessButton
  if (customRender) toReturn = customRender(item)
  return (
    <tr onClick={handleBoxClick}>
      <td >
        {item.name}
      </td>
      <td style={{ textAlign: 'right' }} onClick={(e) => e.stopPropagation()}>
        {toReturn}
      </td>
    </tr>
  )
}

type RequestableListProps = { availableItems: RequestableItem[],  variant: AccessVariant, customRender?: (item: RequestableItem) => React.ReactNode, onClick: (item: RequestableItem) => Promise<void> }
export const RequestableList: React.FC<RequestableListProps> = ({ availableItems, variant, customRender, onClick, }) => {
  let label = "Status"
  if (variant === AccessVariant.REQUEST_SEND) label = "Status"
  if (variant === AccessVariant.OTHER) label = "Anmodning"

  return (
    <Layout.VerticalFlex>
      <table>
        <thead>
          <tr>
            <th style={{ width: "70%" }}>Navn</th>
            <th style={{ width: "30%", textAlign: 'right' }}>{label}</th>
          </tr>
        </thead>
        <tbody>
          {availableItems.map((item, index) => <RequestableListRow variant={variant} item={item} key={index} onClick={onClick} customRender={customRender} />)}
        </tbody>
      </table>
      {availableItems.length === 0 && <span style={{ textAlign: 'center', marginTop: 10 }}>Ingen resulater</span>}
    </Layout.VerticalFlex>
  );
}

export default RequestableList