import styled from 'styled-components';
import { colors } from '../../globals/colors';

const Body = styled.div`
    padding: 0;
    margin: 0;
    padding-bottom: 130px;
`;

const HorizontalFlex = styled.div`
    display: flex;
    flex-direction: row;
`;

const HorizontalFlexAlignCenter = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const HorizontalFlexJustifyCenter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const VerticalFlex = styled.div`
    display: flex;
    flex-direction: column;
`;

const Container = styled.div`
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
`;

const Card = styled.div`
    border-width: 1px;
    border-color: ${colors.lightMain};
    border-style: solid;
    background-color: ${colors.main};
    padding: 10px 20px 10px 20px;
    margin: 14px;
    border-radius: 4px;
`;

const Grid = styled.div`
    display: grid;
`;

export const exports = { Body, HorizontalFlex, HorizontalFlexAlignCenter, VerticalFlex, Container, Card, Grid, HorizontalFlexJustifyCenter }

export default exports;