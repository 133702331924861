import * as React from 'react';
import Layout from '../templates/Layout';
import { BlogPost } from '../../shared/BlogPotsts';
import { colors } from '../../globals/colors';
import HTMLEditor from '../atoms/HtmlEditor';
import { RegularHeading, RootHorizontalItem } from '../atoms/AppComponents';
import Input from '../atoms/Input';
import { GoogleSearchResult } from '../atoms/SEOComponents';
import { useNavigate } from 'react-router-dom';
import { CreateBlogPost } from 'gogoo-common/admin/BlogPost';

const placeholderImg = "https://uploads-ssl.webflow.com/60c8702d9913cdc240da1ebc/629e2ca4aceccd41af76c9d1_anthony-delanoix-hzgs56Ze49s-unsplash.jpg"

enum InputVariants {
    Default = "Default",
    RichText = "RichText",
    TextArea = "TextArea",
    ImageUpload = "ImageUpload"
}
const EditBlogPostItem: React.FC<{ name: string, value: string, setter(val: string): void, variant?: InputVariants, error?: boolean }> = ({ name, value, setter, variant, error }) => {
    const hideCount = variant === InputVariants.RichText || variant === InputVariants.ImageUpload
    const getInputVariant = () => {
        if (variant === InputVariants.TextArea) return <textarea style={{ height: 96 }} value={value} onChange={(e) => setter(e.target.value)} />
        if (variant === InputVariants.RichText) return <HTMLEditor value={value} onChange={setter} />
        if (variant === InputVariants.ImageUpload) return <Input.ImageUpload onChange={setter} />
        return <input value={value} onChange={(e) => setter(e.target.value)} />
    }

    return (
        <Layout.VerticalFlex style={{ marginBottom: 24, borderStyle: "solid", borderWidth: 2, borderColor: error ? colors.danger : "transparent" }}>
            <Layout.HorizontalFlex style={{ justifyContent: 'space-between' }}>
                <b style={{ marginBottom: 3 }}>{name}</b>
                {!hideCount && <span style={{ marginBottom: 3, fontSize: 11 }}>{value.length} tegn</span>}
            </Layout.HorizontalFlex>
            {getInputVariant()}
        </Layout.VerticalFlex>
    )
}

enum EditBlogPostStep {
    Information = "Information",
    Content = "Content"
}

type BlogPostEditorProps = { initial?: BlogPost, organizationId: string, onCreate?: (props: CreateBlogPost) => void, onUpdate?: (props: BlogPost) => void }
export const BlogPostEditor: React.FC<BlogPostEditorProps> = ({ initial, organizationId, onCreate, onUpdate }) => {
    const navigate = useNavigate()
    const [step, setStep] = React.useState<EditBlogPostStep>(EditBlogPostStep.Information)

    const [name, setName] = React.useState(initial?.name)
    const [shorttitle, setShortTitle] = React.useState(initial?.shorttitle)
    const [summary, setSummary] = React.useState(initial?.summary)
    const [content, setContent] = React.useState(initial?.content)
    const [thumbnail, setThumbnail] = React.useState(initial?.thumbnail)

    const handleNext = () => {
        if (step === EditBlogPostStep.Information) setStep(EditBlogPostStep.Content)
        else {
            if (name && shorttitle && summary && content && thumbnail) {
                const data: CreateBlogPost = {
                    organizationOwner: organizationId,
                    name,
                    shorttitle,
                    summary,
                    content,
                    thumbnail,
                }
                if (initial && onUpdate) onUpdate({ ...initial, ...data })
                else if (onCreate) onCreate(data)
            }
        }
    }

    const Information = (
        <Layout.VerticalFlex>
            <h3>Infromation</h3>
            <Layout.HorizontalFlex style={{ flex: 5 }}>
                <Layout.VerticalFlex style={{ flexGrow: 3, paddingRight: 20 }}>
                    <EditBlogPostItem setter={setName} value={name || ''} name={"Kort title (Max 30 tegn)"} />
                    <EditBlogPostItem setter={setShortTitle} value={shorttitle || ''} name={"Lang title (Max 50 tegn)"} />
                    <EditBlogPostItem setter={setSummary} value={summary || ''} name={"Resumé (70-155 tegn)"} variant={InputVariants.TextArea} />
                    <EditBlogPostItem setter={setThumbnail} value={thumbnail || ''} name={"Miniature billede"} variant={InputVariants.ImageUpload} />
                </Layout.VerticalFlex>
                <Layout.VerticalFlex style={{ width: 700 }}>
                    <span style={{ marginTop: 0, marginBottom: 6 }}>Google preview</span>
                    <GoogleSearchResult title={shorttitle || ''} description={summary || ''} />
                    <span style={{ marginTop: 20, marginBottom: 6 }}>App preview</span>
                    <RootHorizontalItem image={!thumbnail || thumbnail.length === 0 ? placeholderImg : thumbnail}>
                        <RegularHeading>{name}</RegularHeading>
                    </RootHorizontalItem>
                </Layout.VerticalFlex>
            </Layout.HorizontalFlex>
        </Layout.VerticalFlex>
    )

    const Content = (
        <EditBlogPostItem setter={setContent} value={content || ''} name="Indhold" variant={InputVariants.RichText} />
    )

    const firstStep = step === EditBlogPostStep.Information

    return (
        <Layout.VerticalFlex>
            <Layout.HorizontalFlex style={{ flexDirection: 'row-reverse' }}>
                <button onClick={() => navigate("/organisation/" + organizationId)}>Gå til organisation</button>
            </Layout.HorizontalFlex>
            {firstStep ? Information : Content}
            <Layout.HorizontalFlex style={{ justifyContent: "space-between" }}>
                <button disabled={firstStep} onClick={() => setStep(EditBlogPostStep.Information)}>Tilbage</button>
                <button onClick={handleNext}>{firstStep ? "Næste" : "Gem og udgiv"}</button>
            </Layout.HorizontalFlex>
        </Layout.VerticalFlex>
    );
}