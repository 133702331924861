import * as React from 'react';
import styled from 'styled-components';
import { AppColors } from '../../globals/colors';
import hooks from '../../globals/hooks';
import { Loading, LoadingState } from '../../globals/states';
import { KultunautEvent } from '../../../../gogoo-common/content/Kultunaut'
import Layout from '../templates/Layout';
import { ReactChildren } from '../../globals/helpers';
import { Product } from '../../shared/Product';
import XIcon from '../../assets/xIcon.svg'
import ShareIconBlack from '../../assets/shareIconBlack.svg'

const colors = AppColors

const ExtraBigtSize = 28
const BiggestFontSize = 22
const BigFontSize = 18
const DefaultFontSize = 16
const SmallFontSize = 12
export const AppWidth = 450

interface SharedTextStyle {
  whiteSpace: "nowrap";
  textOverflow: "ellipsis";
  overflow: "hidden";
  width: "auto";
  maxWidth: number;
}

const sharedTextStyle: SharedTextStyle = {
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
  width: "auto",
  maxWidth: 180,
};

export const BigHeading = styled.h2`
    font-family: "Roboto Condensed", sans-serif;
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: ${ExtraBigtSize}px;
    color: ${colors.mainBlack};
`;

type buttonProps = {
  size: string
}

export const RoundedEdgeButton = styled.div<buttonProps>`
display: flex;
color:white;
justify-content: center;
align-items: center;
height:${({ size }) => (size === "small" ? "10px" : "200px")};
/* width: 75%; */
width:${({ size }) => (size === "small" ? "10px" : "80%")};
background-color: ${AppColors.buttonBlue};
border-radius: 50px;
margin-top: 15px;
height:40px;
`

export const RoundedEdgeBorderedButton = styled.div<buttonProps>`
display: flex;
color:black;
justify-content: center;
align-items: center;
height:${({ size }) => (size === "small" ? "10px" : "200px")};
/* width: 75%; */
width:${({ size }) => (size === "small" ? "10px" : "80%")};
background-color: ${AppColors.mainWhite};
border: 2px solid ${AppColors.buttonBlue};
border-radius: 50px;
margin-top: 15px;
height:40px;
gap:10px;
`

export const CrossButton = styled.div<buttonProps>`
display: flex;
color:orange;
justify-content: center;
align-items: center;
height:${({ size }) => (size === "small" ? "10px" : "200px")};
/* width: 75%; */
width:${({ size }) => (size === "small" ? "10px" : "80%")};
background-color: ${AppColors.mainWhite};
border-radius: 50px 0px 0px 50px;
margin-top: 15px;
height:40px;
`
//24px
export const RegularHeading = styled.span`
    font-size: ${BiggestFontSize}px;
    color: ${colors.mainBlack};
    margin-bottom: 10px;
    font-family: "Roboto Condensed", sans-serif;
    padding: 0px;
    margin-top: 0px;
`
//18px
export const SubHeading = styled.span`
    font-size: ${BigFontSize}px;
    color: ${colors.mainBlack};
    font-family: "Roboto Condensed", sans-serif;
    padding: 0px;
    margin-top: 0px;
`
//16px
export const RegularText = styled.p`
    font-size: ${DefaultFontSize}px;
    font-family: "Roboto Condensed", sans-serif;
    padding: 0px;
    line-height: 17.07px;
    margin-top: 4px;
    color: ${colors.mainBlack};
    margin-bottom: 4px;
`
//16px
export const RegularTextLargeLineHight = styled.p`
    font-size: ${DefaultFontSize}px;
    font-family: "Roboto Condensed", sans-serif;
    padding: 0px;
    line-height: 24px;
    margin-top: 4px;
    margin-bottom: 4px;
    color: ${colors.mainBlack};

`

//12px
export const SmallText = styled.span`
    font-size: ${SmallFontSize}px;
    font-family: "Roboto Condensed", sans-serif;
    color: ${colors.mainBlack};
`

export const ExpandedCompBody = styled.div`
    border-radius: 0px 15px 0px 0px;
    padding: 10px 25px;
    background-color: ${AppColors.mainWhite};
    position:relative;
    top:-40px;
`

export const LongTextNoWrap = styled.p`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0px 15px;
  box-sizing: border-box;
  `;

export enum OrgButtonVariants { Primary, Secondary }
export const RoundButton = styled.div<{ variant: OrgButtonVariants }>`
    background-color: ${(props) => props.variant === OrgButtonVariants.Secondary ? colors.organizationMainBlue : colors.organizationButtonBlue};
    border-style: solid;
    border-color: ${colors.organizationButtonBlue};
    border-width: 3px;
    display: flex;
    justify-content: center; 
    align-items: center;
    border-radius: 30px;
    height: 50px;
    margin-top: 10px;
    margin-bottom: 10px; 
    flex-direction: 'row';
`

export const RootHorizontalItem: React.FC<ReactChildren & { image: string }> = ({ children, image }) => {

  return (
    <div style={{ height: 150 }}>
      <div style={{ display: 'flex', flex: 5, }}>
        <div style={{ display: 'flex', flexDirection: 'row', height: 150, width: '100%' }}>
          <div style={{ flex: 1, backgroundImage: `url(${image})`, backgroundSize: 'cover', height: 150, width: 100 }} />
          <div style={{
            backgroundColor: colors.mainWhite, borderBottomRightRadius: 40,
            flex: 3, padding: 20
          }}>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export const SimpleProductItem: React.FC<ReactChildren & { image: string, product: Product }> = ({ children, image, product }) => {

  return (
    <div style={{ height: 150 }}>
      <div style={{ display: 'flex', flex: 5, }}>
        <div style={{ display: 'flex', flexDirection: 'row', height: 160, width: '100%' }}>
          <div style={{ flex: "1 1 0%", backgroundImage: `url(${image})`, backgroundSize: 'cover', display: "flex", justifyContent: "center" }}>
            <RoundedEdgeButton size="small" style={{ ...sharedTextStyle, position: "relative", top: "68%", height: '30px', width: "130px" }}>
              <LongTextNoWrap>{product.tag}</LongTextNoWrap>
            </RoundedEdgeButton>
          </div>

          <div style={{
            backgroundColor: colors.mainWhite, borderBottomRightRadius: 40,
            flex: 1.4,
            padding: 20,
          }}>
            <BigHeading style={sharedTextStyle}>
              {product.title}
            </BigHeading>
            <SubHeading >
              {product.subheading}
            </SubHeading>
            <RoundedEdgeButton size="large" style={{ marginTop: 5, maxWidth: '170px', width: '100%' }}><LongTextNoWrap
              style={sharedTextStyle}>{product.callToActionPreviewText}</LongTextNoWrap>
            </RoundedEdgeButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export const ExpandedComponent: React.FC<
  ReactChildren & { image: string; product: Product }
> = ({ children, image, product }) => {
  return (
    <div style={{ height: "auto" }}>
      <div style={{ display: "flex", flexDirection: "column", width: "100%", maxWidth: "382px" }}>
        <div
          style={{
            width: "100%",
            //   flex: 1,
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            display: "flex",
            justifyContent: "end",
            height: '300px',
            marginTop: "10px",
            borderRadius: "10px 10px 0px 0px"
          }}
        >
          <CrossButton
            size="small"
            style={{
              ...sharedTextStyle,
              position: "relative",
              top: "5%",
              height: "30px",
              width: "40px",
            }}
          >
            <img alt="xicon" className="rotate" src={XIcon} height={10} />
          </CrossButton>
        </div>
        <ExpandedCompBody >
          <RoundedEdgeButton size="small" style={{ ...sharedTextStyle, height: '30px', width: "95%" }}>
            <LongTextNoWrap>{product.tag}</LongTextNoWrap>
          </RoundedEdgeButton>
          <BigHeading style={{ paddingTop: "10px", fontWeight: "300" }}>
            {product.title}
          </BigHeading>
          <SubHeading style={{ ...sharedTextStyle }}>
            {product.subheading}
          </SubHeading>
          <RoundedEdgeButton size="large" style={{ ...sharedTextStyle, maxWidth: "100%" }}>
            <LongTextNoWrap >{product.callToActionBuyText}</LongTextNoWrap>
          </RoundedEdgeButton>
          <RoundedEdgeBorderedButton size="large" style={{ ...sharedTextStyle, maxWidth: "100%", padding: "0px 0px 0px 15px" }}>
            <div style={{ width: "70%", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img alt="ShareIconBlack" className="rotate" src={ShareIconBlack} height={18} />
              <LongTextNoWrap style={{ paddingLeft: 15 }}>{"Del"}</LongTextNoWrap>
            </div>
          </RoundedEdgeBorderedButton>
          <div style={{ color: "black" }} dangerouslySetInnerHTML={{ __html: product.description }} />
        </ExpandedCompBody>
      </div>
    </div>
  );
};

export const HighlightedProductItem: React.FC<ReactChildren & { image: string, product: Product }> = ({ children, image, product }) => {

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: 300, position: 'relative' }}>
      <div style={{ flex: 3, backgroundImage: `url(${image})`, backgroundSize: 'cover', position: 'relative' }}>
        <RoundedEdgeButton size="large" style={{
          position: "absolute", width: "130px", top: '90%', left: '18%', height: '30px',
          transform: 'translate(-50%, -50%)'
        }}>
          <LongTextNoWrap>{product.tag}</LongTextNoWrap>
        </RoundedEdgeButton>
      </div>

      <div style={{
        backgroundColor: colors.mainWhite, borderBottomRightRadius: 40,
        flex: 2,
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}>
        <div>
          <BigHeading style={sharedTextStyle}>
            {product.title}
          </BigHeading>

        </div>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
          <SubHeading style={{ ...sharedTextStyle, maxWidth: "130px" }}>
            {product.subheading}
          </SubHeading>
          <RoundedEdgeButton size="large" style={{ width: "50%", marginRight: 10 }}>
            <LongTextNoWrap style={sharedTextStyle}>
              {product.callToActionPreviewText}
            </LongTextNoWrap>
          </RoundedEdgeButton>
        </div>

      </div>
    </div>
  );
}



export const LargeEventView: React.FC<{ eventId?: number | string, preloadedEvent?: KultunautEvent; showSmall?: boolean; previewColor?: string; forUICreator?: boolean, setPreloadedEvents?:(event:KultunautEvent)=>void }> = ({ eventId, preloadedEvent, showSmall = false, previewColor, forUICreator = false,setPreloadedEvents }) => {
  const [event, setEvent] = React.useState<KultunautEvent>()
  const [loading, setLoading] = React.useState<LoadingState>()
  React.useEffect(() => {
    const get = async (id: number) => {
      setLoading({ loading: Loading.LOADING })
      const result = await hooks.getEvent(id)
      if (result) {
        setLoading({ loading: Loading.COMPLETE })
        setEvent(result)
        if(setPreloadedEvents) setPreloadedEvents(result)
      } else {
        setLoading({ loading: Loading.ERROR, errorMessage: "This event is invalid" })
      }
    }
    
    if (!eventId && !preloadedEvent) setLoading({ loading: Loading.ERROR, errorMessage: "Provide event or eventid" })
    else if (eventId) {

      if (isNaN(Number(eventId))) setLoading({ loading: Loading.ERROR, errorMessage: "Event id must be a number" })
      else 
    { if(preloadedEvent) {
      setLoading({ loading: Loading.COMPLETE })
      setEvent(preloadedEvent)
    }
      else get(Number(eventId))}
    }

    else setEvent(preloadedEvent)


  }, [eventId, preloadedEvent, setPreloadedEvents])

  if (!loading || loading.loading === Loading.LOADING) return <p style={{ color: colors.mainBlack, padding: 10 }}>Loading event</p>
  if (loading.loading === Loading.ERROR) return <p style={{ color: colors.mainBlack, padding: 10 }}>Error: {loading.errorMessage}</p>
  if (!event) return <p style={{ color: colors.mainBlack, padding: 10 }}>Error: Contact IT Admin</p>

  const dateString = "Date" //getDateRangeString(event)
  const image = event.Image
  const title = event.Title
  const tags = event.Tags
  const organizerName = event.OrganizerName || event.LocationName
  const location = event.OrganizerName ? event.LocationName : event.LocationCity

  return (
    <div
      style={{ marginBottom: forUICreator ? 0 : 45 }}
    >
      <div style={{ height: '100%' }}>
        {showSmall ? (<RootHorizontalItem image={image || 'https://prod-attachments-bucket-gogoo-1.s3.eu-west-1.amazonaws.com/4fe05ab5-7507-4622-b027-62f3286aa5bb'}>
          <RegularHeading style={{ fontSize: 15 }}>
            {title}
          </RegularHeading><br />
          <RegularHeading style={{ fontSize: 10 }}>
            {dateString} {location}
          </RegularHeading>
          <Layout.VerticalFlex>
            {tags.map(tag=><span style={{ fontSize: 12, color: previewColor ?? colors.mainOrange }}>{tag}</span>)}
          </Layout.VerticalFlex>
        </RootHorizontalItem>) : (<>
          <img draggable={false} style={{ height: AppWidth * 0.60, width: AppWidth, marginBottom: -5 }} src={image} alt={""} />
          <div style={{
            paddingLeft: 30, paddingRight: 30, paddingBottom: 20, paddingTop: 20, borderBottomRightRadius: 30,
            backgroundColor: colors.mainWhite
          }}>
            <RegularHeading>{title}</RegularHeading>
            <RegularText>{organizerName} - {location}</RegularText>
            <Layout.HorizontalFlex>
              <Layout.VerticalFlex>
                <RegularText>{dateString}</RegularText>
                <RegularText style={{ color: previewColor ?? colors.mainOrange }}>{tags.join(", ")}</RegularText>
              </Layout.VerticalFlex>
            </Layout.HorizontalFlex>
          </div>
        </>)}
      </div>
    </div>
  )
}

export const FeedBannerItem: React.FC<{ image: string, title: string, description: string }> = ({ image, title, description }) => {

  return (
    <div style={{ width: '100%', minWidth: 400 }}>
      <RootHorizontalItem image={image}>
        <Layout.VerticalFlex>
          <RegularText style={{ marginBottom: 0 }}>{title}</RegularText>
          <SmallText>{description}</SmallText>
        </Layout.VerticalFlex>
      </RootHorizontalItem>
    </div>
  )
}
