import * as React from 'react';
import { LoadingScreen } from '../../components/atoms/Defaults';
import RequestableList, { AccessVariant, RequestableItem } from '../../components/molecules/RequestableList';
import { Navigation } from '../../components/organisms/Navigation';
import Layout from '../../components/templates/Layout';
import hooks from '../../globals/hooks';
import { Loading, LoadingState } from '../../globals/states';
import SearchVenueList from '../../components/molecules/SearchVenueList';
import { useNavigate } from 'react-router-dom';
import apiHooksV2 from '../../globals/api-hooks-v2';
import { KultunautVenue } from 'gogoo-common/content/Kultunaut';


const venueToRequestableItem = (venue: KultunautVenue): RequestableItem => {
  return {
    id: venue.Id.toString(),
    name: venue.Name,
    status: (venue.PremiumMetaData && venue.PremiumMetaData.isValid) ? "Premium" : "Not Premium"
  };
}

const sortRequestableItem = (a: RequestableItem, b: RequestableItem): number => a.name.toUpperCase().localeCompare(b.name.toUpperCase())



export const VenuesIndex: React.FC = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState<LoadingState>()
  const [grantedAccess, setGrantedAccess] = React.useState<KultunautVenue[]>([])
  const [requestedAccess, setRequestedAccess] = React.useState<KultunautVenue[]>([])

  const init = async () => {
    setLoading({ loading: Loading.LOADING })
    const dashboard = await apiHooksV2.listUserVenues()
    if (dashboard) {
      setGrantedAccess(dashboard.grantedAccess)
      setRequestedAccess(dashboard.requestedAccess)
      setLoading({ loading: Loading.COMPLETE })
    } else {
      setLoading({ loading: Loading.ERROR, errorMessage: "Could not load admin profile" })
    }
  }

  const handleVenueRequest = async (venueId: number) => {
    setLoading({ loading: Loading.LOADING })
    const complete = await hooks.requestAccessToVenue(venueId)
    if (complete) {
      await init()
      setLoading({ loading: Loading.COMPLETE })
    } else {
      setLoading({ loading: Loading.ERROR, errorMessage: "Could not complete the rquest" })
    }
  }

  React.useEffect(() => { init() }, [])

  if (!loading || loading.loading === Loading.LOADING) return <LoadingScreen />
  if (loading.loading === Loading.ERROR) return <p>Error: {loading.errorMessage}</p>
  return (
    <Layout.Body>
      <Navigation />
      <Layout.Container>
        <Layout.VerticalFlex>
          <h2>Dine steder</h2>
          <RequestableList availableItems={(grantedAccess || []).map(venueToRequestableItem).sort(sortRequestableItem)} variant={AccessVariant.ACCESS_GRANTED} onClick={async (item) => navigate("/venues/" + item.id)} />
        </Layout.VerticalFlex>
        <Layout.VerticalFlex>
          <p>Dine anmodninger</p>
          <RequestableList availableItems={(requestedAccess || []).map(venueToRequestableItem).sort(sortRequestableItem)}  variant={AccessVariant.REQUEST_SEND} onClick={async () => console.log("")} />
        </Layout.VerticalFlex>
        <Layout.VerticalFlex>
          <p>Søg efter sted og anmod om adgang</p>
          <SearchVenueList excludedVenues={[...requestedAccess, ...grantedAccess]} onVenueClick={handleVenueRequest} />
        </Layout.VerticalFlex>
      </Layout.Container>
    </Layout.Body>

  );
}