

const EventTags = ["Foredrag:Andre foredrag",
    "Foredrag:Arkitektur/design",
    "Foredrag:Historie",
    "Foredrag:Kunst",
    "Foredrag:Litteratur/Lyrik",
    "Foredrag:Livsstil",
    "Foredrag:Miljø/Natur",
    "Foredrag:Musik",
    "Foredrag:Samfund",
    "Markeder:Lagersalg",
    "Markeder:Marked",
    "Musik:Andre koncerter",
    "Musik:Elektrisk",
    "Musik:Funk/blues/R&B",
    "Musik:Gospel/kor/fællessang",
    "Musik:Hiphop/reggae",
    "Musik:Jazz",
    "Musik:Klassisk",
    "Musik:Rock/pop",
    "Musik:Verdensmusik",
    "Natur:Andre naturoplevelser",
    "Natur:Guidet cykeltur",
    "Natur:Guidet vandretur",
    "Popup:Andet",
    "Popup:Fernisering",
    "Popup:Festivaler",
    "Popup:Gastronomi",
    "Popup:Rundvisning/byvandring",
    "Popup:Virtuel",
    "Popup:Workshop",
    "Teater:Andet teater",
    "Teater:Dans",
    "Teater:Musical",
    "Teater:Opera/Operette",
    "Teater:Revy/Kabaret",
    "Teater:Show/Stand up",
    "Teater:Skuespil",
    "Udstillinger:Andre udstillinger",
    "Udstillinger:Billedkunst",
    "Udstillinger:Design og kunsthåndværk",
    "Udstillinger:Galleri",
    "Udstillinger:Historisk",
    "Udstillinger:Installation/skulptur",
    "all-things-live",
    "beer-week",
    "cphcooking",
    "gogoo-aktuelt",
    "gogoo-aktuelt2",
    "gogoo-kampagne1",
    "gogoo-kampagne2",
    "gogoo-kampagne3",
    "gogoo-kampagne4",
    "gogoo-kulturvaerft",
    "gogoo-rabatlink",
    "gogoo-rabattekst",
    "kube",
    "nordstrand",
    "tikko"]

export default EventTags