import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../globals/colors';


const TooltipIcon = styled.div`
  display: flex;
  height: 30px;
  width: 30px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  background-color: ${colors.lightMain};
  color: ${colors.white};
  font-size: 20px;
`;

const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-size: 12px;
  padding-left: 6px;
  padding-right: 6px;
`;

const TooltipContent = styled.div<{ visible: boolean }>`
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: visibility 0.2s, opacity 0.2s;
  background-color: ${colors.lightMain};
  color: ${colors.white};
  border-radius: 4px;
  padding: 8px;
  position: absolute;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  width: max-content;
  white-space: nowrap;
`;

export const Tooltip: React.FC<{description:string, customLabel?: string, customComponent?:ReactElement | null}> = ({ description, customLabel, customComponent = null }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const showTooltip = () => {
    setIsVisible(true);
  };

  const hideTooltip = () => {
    setIsVisible(false);
  };

  return (
    <TooltipWrapper onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
      {customComponent ? customComponent :   <TooltipIcon>{customLabel? customLabel:'?'}</TooltipIcon>}
      <TooltipContent visible={isVisible}>{description}</TooltipContent>
    </TooltipWrapper>
  );
};

