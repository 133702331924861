import * as React from 'react';
import Layout from '../templates/Layout';
import styled from 'styled-components';
import { colors } from '../../globals/colors';
import { KultunautVenue } from '../../../../gogoo-common/content/Kultunaut';
import hooks, { KultunautVenueResult } from '../../globals/hooks';



const KultunautVenueRowRoot = styled.div<{ odd: boolean, loadingOrError: boolean }>`
    padding: 4px;
    font-size: 11px;
    background-color: ${(props) => props.odd ? colors.lightMain : colors.main};
    color: ${(props) => props.loadingOrError ? "transparent" : colors.white};
    justify-content: space-between;
    display: flex;
    &:hover{
      background-color: ${colors.white};
      color: ${(props) => props.loadingOrError ? "transparent" : colors.main};
    }
`;

export const KultunautVenueRow: React.FC<{ kultunautVenue: KultunautVenue, odd: boolean, loadingOrError: boolean, remove(id: number): void }> = ({ kultunautVenue, odd, loadingOrError, remove }) => {
  return (
    <KultunautVenueRowRoot loadingOrError={loadingOrError} odd={odd}>
      <span>{kultunautVenue.Name}</span>
      <p onClick={() => remove(kultunautVenue.Id)}>Fjern</p>
    </KultunautVenueRowRoot>
  )
}

type KultunautSearchResultRowProps = { kultunautVenue: KultunautVenueResult, odd: boolean, loadingOrError: boolean, add(id: number): void, customText?: string }
export const KultunautSearchResultRow: React.FC<KultunautSearchResultRowProps> = ({ kultunautVenue, odd, loadingOrError, add, customText }) => {
  const [added, setAdded] = React.useState(false)
  const handleClick = () => {
    add(Number(kultunautVenue[0]))
    setAdded(true)
  }
  if (added) return <></>
  return (
    <KultunautVenueRowRoot loadingOrError={loadingOrError} odd={odd}>
      <span>{kultunautVenue[1]} ({kultunautVenue[2]})</span>
      <p onClick={handleClick}>{customText ? customText : "Tilføj"}</p>
    </KultunautVenueRowRoot>
  )
}

type SearchVenueListProps = { excludedVenues: KultunautVenue[], onVenueClick: (venueId: number) => void }
export const SearchVenueList: React.FC<SearchVenueListProps> = ({ excludedVenues, onVenueClick }) => {
  const [searchResults, setSearchResults] = React.useState<KultunautVenueResult[]>()
  const [loadingSearchResults, setLoadingSearchResults] = React.useState<boolean>(false)
  const [searchString, setSearchString] = React.useState<string>("")

  const search = async (phrase: string) => {
    if (phrase.length < 1) return
    setLoadingSearchResults(true)
    const result = await hooks.searchVenues(phrase)
    const excludedVenueIds = excludedVenues.map((i) => i.Id)
    const filtered = result.items.filter((i) => !excludedVenueIds.includes(Number(i[0])))
    setSearchResults(filtered)
    setLoadingSearchResults(false)
  }

  const handleSearchStringChange = async (text: string) => {
    setSearchString(text)
    if (text.length > 2) await search(text)
    else setSearchResults(undefined)
  }

  return (
    <Layout.VerticalFlex>
      <input value={searchString} onChange={(e) => handleSearchStringChange(e.target.value)} style={{ marginBottom: 8 }} />
      {searchResults && searchResults.map((i, key) => <KultunautSearchResultRow add={(item) => onVenueClick(item)} key={key} kultunautVenue={i} customText='Anmod om adgang'
        odd={key % 2 === 0} loadingOrError={loadingSearchResults} />)}
    </Layout.VerticalFlex>
  );
}

export default SearchVenueList