import styled from 'styled-components';

const Spinner: React.FC<{compact?: boolean;}> = ({compact = false}) => (
  <StyledSpinner compact={compact} viewBox="0 0 50 50">
    <circle
      className="path"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="4"
    />
  </StyledSpinner>
);

const StyledSpinner = styled.svg<{compact: boolean;}>`
  animation: rotate 2s linear infinite;
  margin: ${({compact}) => compact ? '10px' : '-25px 0 0 -25px' };
  width:  ${({compact}) => compact ? '20px' : '50px' };
  height: ${({compact}) => compact ? '20px' : '50px' };
  
  & .path {
    stroke: #EB7F5A;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

export default Spinner;