import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Navigation } from '../../components/organisms/Navigation';
import Layout from '../../components/templates/Layout';
import { colors } from '../../globals/colors';
import apiHooksV2 from '../../globals/api-hooks-v2';
import { LoadingScreen } from '../../components/atoms/Defaults';


const ButtonStyles = { backgroundColor: colors.primary, width: '100%', margin: 30, justifyContent: 'center', minHeight: 160, alignItems: 'center', cursor: 'pointer' }

export const IndexPage: React.FC = () => {
  const navigate = useNavigate()

  const [loading, setLoading] = React.useState(true)

  const getUser = async () => {
    setLoading(true)
    const user = await apiHooksV2.getAdminUser()
    if (!user) await apiHooksV2.signup()
    setLoading(false)
  }

  React.useEffect(() => {
    getUser()
  }, [])


  if (loading) return <LoadingScreen />

  return (
    <Layout.Body>
      <Navigation />
      <Layout.Container>
        <Layout.HorizontalFlex>
          <h2>Velkommen til gogoo</h2>
        </Layout.HorizontalFlex>

        <Layout.HorizontalFlex style={{ justifyContent: 'space-between' }}>
          <Layout.HorizontalFlex onClick={() => navigate('/organisations')} style={ButtonStyles}>
            <b>Gå til organisationer</b>
          </Layout.HorizontalFlex>
          <Layout.HorizontalFlex onClick={() => navigate('/venues')} style={ButtonStyles}>
            <b>Gå til event udbyder</b>
          </Layout.HorizontalFlex>
        </Layout.HorizontalFlex>
      </Layout.Container>
    </Layout.Body>

  );
}