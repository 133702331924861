import * as React from 'react';
import Layout from '../templates/Layout';


export const BreadCrumb: React.FC<{ links: { label: string, url: string }[] }> = ({ links }) => {

  return (
    <Layout.HorizontalFlex style={{}}>
      {links.map(({ label, url }, key) => <div key={key}>
        <span style={key === 0 ? {} : { marginRight: 12 }} >{key !== 0 && "/"}</span>
        <a style={{ textDecoration: 'none', marginRight: 12, fontWeight: key!==(links.length-1)? 'normal': 'bold' }} href={url}>{label}</a>
      </div>)}
    </Layout.HorizontalFlex>
  )
}



