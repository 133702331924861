import * as React from 'react';
import Layout from '../templates/Layout';

const ArrowDownward: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z" /></svg>
)

const Add: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none" /><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" /></svg>
)

const CheckIcon: React.FC<{ size?: number, color?: string }> = ({ size, color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" height={size || 24} viewBox="0 -960 960 960" width={size || 24}><path fill={color || "#fff"} d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" /></svg>
)

const Remove: React.FC<{ size?: number, color?: string }> = ({ size, color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" height={size || 24} width={size || 24} viewBox="0 -960 960 960"><path fill={color || "#fff"} d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" /></svg>
)

const CompletionComponent: React.FC<{ active: boolean }> = ({ active }) => {
    const color = active ? "#7cff5b" : "#ff2f2f"
    return (
        <Layout.HorizontalFlex>
            <div style={{ height: 15, width: 15, backgroundColor: color, borderRadius: "50%", marginRight: 10, marginTop: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {active ? <CheckIcon size={12} /> : <Remove size={12} />}
            </div>
        </Layout.HorizontalFlex>
    )
}

const icons = { ArrowDownward, Add, Remove, CompletionComponent }

export default icons;
