import * as React from 'react';
import { colors } from '../../globals/colors';
import Layout from '../templates/Layout';


export const GoogleSearchResult: React.FC<{ title: string, description: string }> = ({ title, description }) => {

    const date = (new Date()).toLocaleDateString()

    return (
        <Layout.VerticalFlex style={{ backgroundColor: colors.white, padding: 10, borderRadius: 5 }}>
            <span style={{ color: "#8f8f8f", fontSize: 9 }}>https://www.gogoo.app › Blog › {title}</span>
            <span style={{ color: "#1a0dab" }}>{title}</span>
            <span style={{ color: "#4d5156", fontSize: 12 }}>{date} - {description}</span>
        </Layout.VerticalFlex>
    )
}

