import { BlogPost } from "./BlogPotsts";
import { OrganizationProduct, Organization } from "./Organization";
import { QRCode } from "./QRCodes";
import { GogooNotification, NotificationType } from "./Notifications";
import { Banner } from "./CMS";
import { User } from "./User";
import { KultunautEvent, KultunautVenue } from "./KultunautTypes";
import { UserMetaData } from "./UserMetaData";
import { Product } from "./Product"

export enum Authorization {
    SUPER_ADMIN = 100,
    GOGOO_ADMIN = 90,
    UNAUTHORIZED = 0
}

export enum OrganisationAuthorizationTypes {
    ORGANIZATION_ADMIN = 50,
    ORGANIZATION_READ_WRITE_MEMBER = 40,
    ORGANIZATION_READ_MEMBER = 20,
    ORGANIZATION_ACCESS_REQUESTED_MEMBER = 10,
    NONE = 0,
}

export const OrganisationAuthorizationTypesToString = (authType: OrganisationAuthorizationTypes): string => {
    switch (authType) {
        case OrganisationAuthorizationTypes.ORGANIZATION_ADMIN: return "Admin"
        case OrganisationAuthorizationTypes.ORGANIZATION_READ_WRITE_MEMBER: return "Fuld adgang"
        case OrganisationAuthorizationTypes.ORGANIZATION_READ_MEMBER: return "Læse adgang"
        case OrganisationAuthorizationTypes.ORGANIZATION_ACCESS_REQUESTED_MEMBER: return "Anmodning om adgang"
        default: return "Ingen adgang"
    }
}

export type OrganisationAuthorization = {
    organizationId: string;
    authorization: OrganisationAuthorizationTypes;
}


export type OrganizationDashboard = {
    grantedAccess: Organization[];
    requestedAccess: Organization[];
    notGrantedAccess: Organization[];
    authorization: Authorization;
}


export enum VenueAuthorizationTypes {
    VENUE_OWNER = 100,
    VENUE_ACCESS_REQUESTED_MEMBER = 10,
    NONE = 0,
}
export const VenueAuthorizationTypesToString = (authType: VenueAuthorizationTypes): string => {
    switch (authType) {
        case VenueAuthorizationTypes.VENUE_OWNER: return "Ejer"
        case VenueAuthorizationTypes.VENUE_ACCESS_REQUESTED_MEMBER: return "Anmodning om adgang"
        default: return "Ingen adgang"
    }
}

export type VenueAuthorization = {
    venueId: number;
    authorization: VenueAuthorizationTypes;
}

export type MissingUser = {
    missingUser: true;
}


export type VenueDashboard = {
    grantedAccess: KultunautVenue[];
    requestedAccess: KultunautVenue[];
    authorization: Authorization;
}

export type AdminApiResponse  = {
    success: boolean;
    message?: string;
}

export type GetOrganizationDashboardResult = {
    organization: Organization;
    accessRequests?: AdminUser[];
    accessGranted: (AdminUser & { organisationAuthorization: OrganisationAuthorizationTypes })[];
    articles: BlogPost[];
    writePermission: boolean;
    qrCodes: QRCode[];
}

export type AdminUser = {
    userId: string;
    displayName: string;
    email: string;
    authorization: Authorization;
    organisationAuthorizations: OrganisationAuthorization[];
    venueAuthorizations?: VenueAuthorization[];
}

export type AdminUserRequest = {
    email: string;
    name: string;
}

export type AdminSegmentRequest = {
    categoriesSelected?: string[];
}

export type AdminSendNotificationToSegment = AdminSegmentRequest & {
    title: string;
    subTitle: string;
    scheduledTime: number;
    notificationType: NotificationType
    input?: string;
    testUsers?: string[];
}

export type ApproveOrganizationAccessRequest = {
    organizationId: string;
    adminUserId: string;
    permission: OrganisationAuthorizationTypes;
}

const checkOrgPermission = (user: AdminUser, organizationId: string, minPermission: OrganisationAuthorizationTypes): boolean => {
    if (!user || !organizationId) return false
    if (user.authorization >= Authorization.GOGOO_ADMIN) return true
    const permission = user.organisationAuthorizations.find((item) => item.organizationId === organizationId)
    if (permission && permission.authorization >= minPermission) return true
    return false
}

export const HasReadPermissions = (user: AdminUser,  options: { organizationId?: string, venueId?: number }): boolean => {
    if(user.authorization >= Authorization.GOGOO_ADMIN) return true
    if (options.organizationId) return checkOrgPermission(user, options.organizationId, OrganisationAuthorizationTypes.ORGANIZATION_READ_MEMBER)
    if (options.venueId && user.venueAuthorizations) {
        const authorization = user.venueAuthorizations?.find((i)=> i.venueId === options.venueId)?.authorization
        if(authorization && authorization >= VenueAuthorizationTypes.VENUE_OWNER) return true
    }
    return false
}

export const HasWritePermissions = (user: AdminUser, options: { organizationId?: string, venueId?: number }): boolean => {
    if(user.authorization >= Authorization.GOGOO_ADMIN) return true
    if (options.organizationId) return checkOrgPermission(user, options.organizationId, OrganisationAuthorizationTypes.ORGANIZATION_READ_WRITE_MEMBER)
    if (options.venueId && user.venueAuthorizations) {
        const authorization = user.venueAuthorizations?.find((i)=> i.venueId === options.venueId)?.authorization
        if(authorization && authorization >= VenueAuthorizationTypes.VENUE_OWNER) return true
    }
    return false
}

export const IsOrgAdmin = (user: AdminUser, organizationId: string): boolean => {
    return checkOrgPermission(user, organizationId, OrganisationAuthorizationTypes.ORGANIZATION_ADMIN)
}

export const IsGogooAdmin = (user: AdminUser): boolean => {
    return user.authorization >= Authorization.GOGOO_ADMIN
}

export type MessageOption = {
    title: string;
    body: string;
    data?: GogooNotification;
    sound?: 'default' | null | {
        critical?: boolean;
        name?: 'default' | null;
        volume?: number;
    };
}

export type BulkNotificationMetaInformation = { bulkFileId: string, date: string }

export type BulkNotification = {
    id: string;
    userIds: string[],
    message: MessageOption,
    executionTime: number
}

export type BulkFile = {
    bulkFileId: string,
    queue: BulkNotification[],
    inProgress: BulkNotification[],
    completed: BulkNotification[],
}


export type ManageBannerPublishProps = {
    bannerId: string,
    published: boolean 
}

export type BannerClickProps = {
    banner: Banner;
    user: User;
}

export type BannerClick = {
    bannerId: string;
    logId: string;
    time: string;
    banner: Banner;
    user: User;
}


export type DetailedBanner = {
    banner: Banner
    clicksCSV: string[][]  
}


export type AdminSetOrganizationProductRequest = {
    organizationId: string;
    organizationProduct: OrganizationProduct
}

export type AdminDetailedVenue = {
    venue: KultunautVenue;
    audience: UserMetaData[];
    accessRequests: AdminUser[];
    accessGranted: AdminUser[];
    events: KultunautEvent[];
    products: Product[];
}

export type ApproveVenueAccess = {
    venueId: number;
    adminUserId: string;
    venueAuthorization: VenueAuthorizationTypes
}