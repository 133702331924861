import { Auth } from 'aws-amplify';
import * as React from 'react';
import { LoadingScreen } from '../../components/atoms/Defaults';
import Logo from '../../assets/gogoologo.svg'
import { colors } from '../../globals/colors';
import Layout from '../../components/templates/Layout';
import Spinner from '../../components/atoms/LoadingSpinner';

enum LoginState {
    Login = "LOGIN",
    Signup = "SIGNUP",
    MFA = "MFA",
    ForgotPassword = "FORGOT PASSWORD",
    SetupNewPassword = "SETUP NEW PASSWORD"
}

const LoginStateToDisplayText = (loginState: LoginState): string => {
    if (loginState === LoginState.Login) return "Login på din profil"
    if (loginState === LoginState.Signup) return "Opret en ny profil"
    if (loginState === LoginState.ForgotPassword) return "Få et nyt password"
    if (loginState === LoginState.SetupNewPassword) return "Opret nyt password"
    if (loginState === LoginState.MFA) return "Tjek venligst din indbakke"

    return loginState
}

const LoginStateToButtonText = (loginState: LoginState): string => {
    if (loginState === LoginState.Login) return "Login"
    if (loginState === LoginState.Signup) return "Opret"
    if (loginState === LoginState.ForgotPassword) return "Tilsend nyt password"
    if (loginState === LoginState.SetupNewPassword) return "Opret nyt password"
    if (loginState === LoginState.MFA) return "Gå tilbage til login"

    return loginState
}

const LogoutPage: React.FC = () => {
    const logout = async () => {
        await Auth.signOut()
        window.location.reload()
    }
    React.useEffect(() => { logout() }, [])
    return (
        <Layout.Body>
            <LoadingScreen />
        </Layout.Body>

    );
}

const LoginPage: React.FC = () => {
    const [loginState, setLoginState] = React.useState(LoginState.Login)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [error, setError] = React.useState<string>()
    const [email, setEmail] = React.useState<string>()
    const [password, setPassword] = React.useState<string>()
    const [validationState, setValidationState] = React.useState<any>({
        uppercase: false,
        lowercase: false,
        number: false,
        specialChar: false,
        length: false,
    });
    const [newPassword, setNewPassword] = React.useState<string>()

    const [mfa, setMFA] = React.useState<string>()

    const [name, setName] = React.useState<string>("")

    const isSignup = loginState === LoginState.Signup
    const isLogin = loginState === LoginState.Login
    const isForgotPassword = loginState === LoginState.ForgotPassword
    const isSetupNewPassword = loginState === LoginState.SetupNewPassword


    const changeState = (newState: LoginState) => () => {
        setError('')
        setLoginState(newState)
    }

    const mfaMissing = () => setError("6-Cifret engangskode er ikke udfyldt tilstrækkeligt")
    const finalError = () => setError("Der skete en fejl. Venligst kontakt support")
    const setPasswordMissing = () => setError("Password er ikke udfyldt tilstrækkeligt")

    React.useEffect(() => {
        setError('')
    }, [email, password, newPassword, mfa, name])

    const handlePasswordChange = (passwordValue: any) => {
        const newPassword = passwordValue;
        setPassword(newPassword);

        const newState = {
            uppercase: /[A-Z]/.test(newPassword),
            lowercase: /[a-z]/.test(newPassword),
            number: /[0-9]/.test(newPassword),
            // /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/ original regex, removed back-slash for eslint
            specialChar: /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/.test(newPassword),
            length: newPassword.length >= 8,
        };

        setValidationState(newState);
    };

    const submit = async () => {
        if (loading) return
        if (!email || email.length < 3) return setError("Email er ikke udfyldt")
        setLoading(true)
        const localEmail = (email.toLowerCase()).split(' ').join('')
        try {
            if (loginState === LoginState.Login) {
                if (!password || password.length < 3) return setPasswordMissing()
                const confirmedUser = await Auth.signIn({ username: localEmail, password })
                if (confirmedUser) window.location.replace('/');
            }
            else if (loginState === LoginState.MFA) setLoginState(LoginState.Login)
            else if (loginState === LoginState.Signup) {
                if (!password || password.length < 3) return setPasswordMissing()

                const { userConfirmed } = await Auth.signUp({
                    username: localEmail,
                    password,
                    attributes: {
                        name
                    }

                })
                setError('')
                if (!userConfirmed) setLoginState(LoginState.MFA)
            }
            else if (loginState === LoginState.ForgotPassword) {
                await Auth.forgotPassword(localEmail)
                setPassword('')
                setError('')
                setLoginState(LoginState.SetupNewPassword)
            }
            else if (loginState === LoginState.SetupNewPassword) {
                if (!mfa) {
                    mfaMissing()
                    setLoading(false)
                    return
                }
                if (!newPassword || newPassword.length < 3) {
                    setLoading(false)
                    setPasswordMissing()
                    return
                }
                await Auth.forgotPasswordSubmit(localEmail, mfa, newPassword)
                setError('')
                setLoginState(LoginState.Login)
            }
        } catch (error) {
            console.log(error)
            //@ts-ignore
            if (error.name === "UserNotConfirmedException") {
                try {
                    await Auth.resendSignUp(localEmail)
                    setLoginState(LoginState.MFA)
                } catch (error) {
                    finalError()
                    console.log(error)
                }
                //@ts-ignore
            } else if (error.name === "UserNotFoundException") {
                setError("Vi kunne ikke finde din konto. Måske bruger du log ind med facebook?")
                //@ts-ignore
            } else if (error.name === "NotAuthorizedException") {
                setError("Password eller email er ikke korrekt")
                //@ts-ignore
            } else if (error.name === "CodeMismatchException") {
                setError("2-Faktor kode er ikke korrekt")
                //@ts-ignore
            } else if (error.name === "LimitExceededException") {
                setError("Du har prøvet for mange gange, prøv igen senere")
                //@ts-ignore
            } else if (error.name === "ExpiredCodeException") {
                setError("Koden er uløbet")
                setLoginState(LoginState.ForgotPassword)
                //@ts-ignore
            } else if (error.name === "UsernameExistsException") {
                setError("Der findes allerede en konto med denne email. Login i stedet for.")
                //@ts-ignore
            } else if (error.name === "InvalidPasswordException") {
                setError("Ugyldigt password. Brug mindst 8 tegn.")
                //@ts-ignore
            } else if (error.name === "NetworkError") {
                setError("Vi oplevede en netværks fejl. Venligst prøv igen.")
                //@ts-ignore
            } else if (error.name === "InvalidParameterException") {
                setError("En eller flere parametre er ugyldige.")
                //@ts-ignore
            } else if (error.name === "PasswordResetRequiredException") {
                setError("Du skal nulstille dit password")
                await Auth.forgotPassword(localEmail)
                setPassword('')
                setError('')
                setLoginState(LoginState.SetupNewPassword)
                //@ts-ignore
            } else {
                console.log(error)
                finalError()
            }

        }

        setLoading(false)
    }

    React.useEffect(() => {
        if (password && isSignup) handlePasswordChange(password)
    }, [password, isSignup])

    return (
        <Layout.Container>
            <form onSubmit={(e) => { e.preventDefault(); submit() }}>
                <div style={{ width: "100%", height: "100vh", display: 'flex', opacity: loading ? 0.6 : 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    {loading && (<div style={{ position: "absolute", height: "100%", width: "100%", justifyContent: "center", alignItems: "center", alignContent: "center", display: "flex" }}>
                        <Spinner />
                    </div>)}
                    <img alt="logo" className="rotate" src={Logo} height={50} />
                    <h1 style={{ textAlign: 'center', color: colors.white }}>{LoginStateToDisplayText(loginState)}</h1>
                    <Layout.VerticalFlex>
                        {(isSetupNewPassword) && <p style={{ marginBottom: 20 }}>Vi har sendt en mail med en 6 cifret kode.</p>}
                        {(isSignup || isForgotPassword || isLogin) && <input placeholder='Email' style={{ marginBottom: 20 }} type="email" value={email} onChange={(e: any) => setEmail(e?.target.value.toString())} id="email" />}
                        {(isSignup || isLogin) && <input type="password" placeholder='Password' value={password} onChange={(e: any) => setPassword(e?.target.value.toString())} id="password" style={{ marginBottom: 20 }} />}
                        {password && isSignup && (
                            <div>
                                {Object?.keys(validationState)?.some((key: any) => !validationState[key]) ? (
                                    <ul>
                                        {!validationState?.uppercase && <li>Mindst ét stort bogstav</li>}
                                        {!validationState?.lowercase && <li>Mindst ét lille bogstav</li>}
                                        {!validationState?.number && <li>Mindst ét tal</li>}
                                        {!validationState?.specialChar && <li>Mindst ét specialtegn</li>}
                                        {!validationState?.length && <li>Mindst 8 tegn i længde</li>}
                                    </ul>
                                ) : (
                                    <p>Adgangskoden opfylder alle krav</p>
                                )}
                            </div>
                        )}
                        {(isSignup) && <input type="name" style={{ marginBottom: 20 }} placeholder='Name' value={name} onChange={(e: any) => setName(e?.target.value.toString())} id="name" />}

                        {isLogin && <p style={{ marginBottom: 20, cursor: 'pointer', textDecoration: 'underline' }} onClick={changeState(LoginState.ForgotPassword)}>Glemt adgangskode</p>}
                        {isSetupNewPassword && <input style={{ marginBottom: 20 }} placeholder='6 cifret engangskode' value={mfa} onChange={(e: any) => setMFA(e?.target.value.toString())} />}
                        {isSetupNewPassword && <input type="password" placeholder='Nyt password' value={newPassword} onChange={(e: any) => setNewPassword(e?.target.value.toString())} id="new-password" style={{ marginBottom: 20 }} />}
                        <button onClick={submit} style={{ marginBottom: 20, width: '100%' }}>{LoginStateToButtonText(loginState)}</button>

                        {error && error !== '' && <p style={{ color: colors.danger, marginBottom: 20 }}>{error}</p>}
                        {isLogin && <button style={{ marginBottom: 20, backgroundColor: colors.lightMain }} onClick={changeState(LoginState.Signup)}>Har du ikke en profil? Opret dig her</button>}
                        {(isSignup) && <button style={{ marginBottom: 20, backgroundColor: colors.lightMain }} className='light' onClick={changeState(LoginState.Login)}>Har du allerede en profil? Login her</button>}
                        {(isForgotPassword) && <button style={{ marginBottom: 20, backgroundColor: colors.lightMain }} className='light' onClick={changeState(LoginState.Login)}>Gå tilbage til login</button>}

                        <p className='small'>Har du brug for assistance? Kontakt: <a href='mailto:kontakt@gogoo.app'>kontakt@gogoo.app</a> </p>
                    </Layout.VerticalFlex>
                </div>
            </form>
        </Layout.Container>

    );
}

export { LogoutPage, LoginPage }
