import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../globals/colors';

const AccordionWrapper = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 10px;
`;

const AccordionHeader = styled.button`
  background-color: ${colors.background};
  padding: 10px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  border: none;
`;

const AccordionContent = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  padding: 10px;
`;

interface AccordionItemProps {
  title:  React.ReactNode;
  children: React.ReactNode;
}

const AccordionItem: React.FC<AccordionItemProps> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <AccordionWrapper>
      <AccordionHeader onClick={toggleAccordion}>
        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
        {title}
            {isOpen ? "-" : "+"}
        </div>
        </AccordionHeader>
      <AccordionContent isOpen={isOpen}>{children}</AccordionContent>
    </AccordionWrapper>
  );
};

interface AccordionProps {
  title:  React.ReactNode;
  children: React.ReactNode;
}

export const Accordion: React.FC<AccordionProps> = (item) => {
  return (
    <div style={{marginTop:10, width:"100%"}}>
        <AccordionItem title={item.title}>
          {item.children}
        </AccordionItem>
    </div>
  );
};


