export const colors = {
    white: '#fff',
    main: '#333',
    lightMain: '#4d4d4d',
    background: '#141414',
    primary: '#0D5A5B',
    danger: '#ff4242'
}

// 0D5A5B -> EB7F5A

export const AppColors = {
    mainBlue: '#cfe4eb',
    mainBlueLight: '#edf7fa',
    mainBlueDark: '#3D707B',
    mainOrange: '#0D5A5B',
    mainWhite: '#fff',
    buttonBlue: '#76B6C4',
    mainBlack: '#18343A',
    listItemColor: "#a7ccd4",
    lightGray: '#C2C2C2',
    organizationMainBlue: '#ECF1FB',
    organizationButtonBlue: '#637B8F',
    organizationSecondaryButtonBlue: '#A6BDD0',
}
