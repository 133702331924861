import * as React from 'react';
import Layout from '../templates/Layout';
import { colors } from '../../globals/colors';




export const DataBox: React.FC<{ data: string | number, label: string }> = ({ data, label }) => {

  return (
    <Layout.VerticalFlex style={{ width: 140, borderColor: '#ffffff43', borderWidth: 1, borderStyle: 'solid' }}>
      <Layout.HorizontalFlexJustifyCenter style={{ backgroundColor: colors.lightMain }}>
        <h3>{data}</h3>
      </Layout.HorizontalFlexJustifyCenter>
      <Layout.HorizontalFlexJustifyCenter style={{ backgroundColor: colors.main, paddingTop: 12, paddingBottom: 12}}>
        <span style={{fontSize: 12}}>{label}</span>
      </Layout.HorizontalFlexJustifyCenter>
    </Layout.VerticalFlex>
  )
}



