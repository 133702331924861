import * as React from 'react';
import { colors } from '../../globals/colors';

export default function UserSettingMenu() {

  return (
    <div>
      <a style={{ color: colors.white }} href='/logout'>Log out</a>
    </div>
  );
}
