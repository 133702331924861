import * as React from 'react';
import { useParams } from 'react-router-dom';
import { LoadingScreen } from '../../components/atoms/Defaults';
import { Navigation } from '../../components/organisms/Navigation';
import Layout from '../../components/templates/Layout';
import { colors } from '../../globals/colors';
import { Loading, LoadingState } from '../../globals/states';
import { Tooltip } from '../../components/atoms/Tooltip';
import { KultunautDetailedEvent, KultunautVenue, TicketListItem } from '../../../../gogoo-common/content/Kultunaut'
import { StatusComponent } from '../../components/atoms/StatusComponent';
import apiHooksV2 from '../../globals/api-hooks-v2';
import CustomTable from '../../components/molecules/CustomTable';
import { Stepper } from '../../components/molecules/Stepper';
import Input from '../../components/atoms/Input';
import { kultuanutDateToNodeDate } from '../../globals/helpers';
import EventMetaData, { LastChanceTicketData } from 'gogoo-common/content/EventMetaData';
import styled from 'styled-components';
import LoadingSpinner from '../../components/atoms/LoadingSpinner';


const ticketListItemToSelectItem = (ticketListItem: TicketListItem) => ({ key: ticketListItem.TicketUrl, displayName: ticketListItem.TicketTitle + ' (' + ticketListItem.TicketPrice + ' dkk)' })

const ticketListItemToSelectItemWithUndefined = (ticketListItem?: TicketListItem) => !ticketListItem ? undefined : ticketListItemToSelectItem(ticketListItem)


const InputLabel = styled.span({ marginBottom: 3, marginTop: 6, fontSize: 14, fontWeight: 'bold', lineHeight: 1.5 })

const Spacer: React.FC = ({ }) => {
  return <Layout.VerticalFlex>
    <div style={{ height: 2, backgroundColor: colors.white, flex: 1, padding: 1, opacity: 0.2, marginTop: 12, marginBottom: 12 }}></div>
  </Layout.VerticalFlex>
}


const dateInputHandler = (input: string) => {
  const date = new Date(input)
  return date.toUTCString()
}

const dateOutputHandler = (input: string) => {
  const date = new Date(input)
  date.setMinutes(date.getMinutes()-date.getTimezoneOffset())
  return date.toISOString().replace('Z', '')
}


const LastChanceComponent: React.FC<{ event: KultunautDetailedEvent }> = ({ event }) => {
  const [step, setStep] = React.useState(event.EventMetaData?.ticket ? 3 : 0)
  const [loading, setLoading] = React.useState(false)
  const [price, setPrice] = React.useState(event.EventMetaData?.ticket?.previousPrice || 0)
  const [discountedPrice, setDiscountPrice] = React.useState(event.EventMetaData?.ticket?.discountedPrice || 0)
  const [daysBefore, setDaysBefore] = React.useState(0)

  const [ticketLink, setTicketLink] = React.useState(event.EventMetaData?.ticket?.ticketUrl || '')
  const [discountCode, setDiscountCode] = React.useState(event.EventMetaData?.ticket?.discountCode || '')

  const [salesStart, setSalesStart] = React.useState(event.EventMetaData?.ticket?.saleUCTStartDate)
  const [salesEnd, setSalesEnd] = React.useState(event.EventMetaData?.ticket?.salesUCTEndDate)



  const validProduct = (ticketLink.length > 0) && (daysBefore > 0 || step === 3) && (price > discountedPrice)

  const createTicket = async () => {
    const input = salesStart || ''
    if (validProduct) {
      setLoading(true)
      const salesStartDate = kultuanutDateToNodeDate(event.Startdate, event.Starttime)
      salesStartDate.setDate(salesStartDate.getDate() - daysBefore)

      const localStartDate = salesStart || salesStartDate.toUTCString()
      const localEndDate = salesEnd || kultuanutDateToNodeDate(event.Startdate, event.Starttime).toUTCString()

      const ticket: LastChanceTicketData = {
        type: "EventTicket",
        internalName: event.Title,
        saleUCTStartDate: localStartDate,
        salesUCTEndDate: localEndDate,
        previousPrice: price,
        discountedPrice: discountedPrice,
        ticketUrl: ticketLink,
        discountCode: discountCode.length > 0 ? discountCode : undefined,
        visible: true
      }
      const metaData:EventMetaData = event.EventMetaData || {
        eventId: event.Id,
        endDateUnix: kultuanutDateToNodeDate(event.Enddate).getTime(),
        views: 0,
        latitude: event.Lat,
        longitude: event.Lon
      }
      await apiHooksV2.addEventMetaData({
        ...metaData,
        ticket
      })
      if(step===1) setStep(2)
      setLoading(false)
    }
  }



  const startDate = kultuanutDateToNodeDate(event.Startdate, event.Starttime)
  startDate.setDate(startDate.getDate() - daysBefore)

  const detailsStep = (
    <Layout.VerticalFlex>
      <Spacer />
      <Layout.VerticalFlex>
        <Layout.HorizontalFlex style={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Layout.HorizontalFlex>
            <Layout.VerticalFlex style={{ paddingRight: 12 }}>
              <InputLabel>Oprindelige pris</InputLabel>
              <Input.InputWithSuffix onChange={(v) => setPrice(Number(v))} suffix='dkk' value={price} type='number' />
            </Layout.VerticalFlex>
            <Layout.VerticalFlex>
              <InputLabel>Pris efter rabat</InputLabel>
              <Input.InputWithSuffix onChange={(v) => setDiscountPrice(Number(v))} suffix='dkk' value={discountedPrice} type='number' />
            </Layout.VerticalFlex>
          </Layout.HorizontalFlex>
          {price > 0 && discountedPrice > 0 && <span style={{ color: price > discountedPrice ? colors.white : 'red' }}>Rabat: {price - discountedPrice} DKK ({((1 - (discountedPrice / price)) * 100).toFixed() + "% rabat"}) <Tooltip description='Vi anbefaler at man giver mindst 30% rabat for at opnå det bedst mulige resultat.' /></span>}
        </Layout.HorizontalFlex>
        <Spacer />
        {step === 1 &&
          <Layout.HorizontalFlex style={{ justifyContent: 'space-between' }}>
            <Layout.VerticalFlex >
              <InputLabel>Udgiv antal dage før</InputLabel>
              <Input.InputWithSuffix onChange={(v) => setDaysBefore(Number(v))} suffix='dage' type='number' />
            </Layout.VerticalFlex>
            {daysBefore > 0 && <Layout.VerticalFlex style={{ alignItems: 'flex-end' }}>
              <span>Event dato: {kultuanutDateToNodeDate(event.Startdate, event.Starttime).toLocaleString()}</span>
              <span>Udgivelses dato: {startDate.toLocaleString()}</span>
            </Layout.VerticalFlex>}
          </Layout.HorizontalFlex>}
        {step === 3 && salesStart && salesEnd && (
          <Layout.HorizontalFlex style={{ justifyContent: 'space-between' }}>
            <Layout.VerticalFlex style={{ paddingRight: 12 }}>
              <InputLabel>Salgs start dato</InputLabel>
              <Input.InputWithSuffix onChange={(v) => setSalesStart(dateInputHandler(v))} customWidth={200} suffix='udgives' type='datetime-local' value={dateOutputHandler(salesStart)} />
              <InputLabel>Salgs slut dato</InputLabel>
              <Input.InputWithSuffix onChange={(v) => setSalesEnd(dateInputHandler(v))} customWidth={200} suffix='afsluttes' type='datetime-local' value={dateOutputHandler(salesEnd)} />
            </Layout.VerticalFlex>
            <Layout.VerticalFlex >
            </Layout.VerticalFlex>
          </Layout.HorizontalFlex>
        )}
        <Spacer />
        <Layout.HorizontalFlex style={{ justifyContent: 'space-between' }}>
          <Layout.VerticalFlex style={{ minWidth: 400 }}>
            <InputLabel>Billetlink</InputLabel>
            <input onChange={(v) => setTicketLink(v.target.value)} value={ticketLink} />
            {step !== 3 && event.TicketList && event.TicketList.length > 0 && <InputLabel style={{ textAlign: 'center', fontWeight: 'normal' }}><i>Eller vælge fra eksisterende</i></InputLabel>}
            {step !== 3 && event.TicketList && event.TicketList.length > 0 && <Input.Select selected={ticketListItemToSelectItemWithUndefined(event.TicketList.find((i) => i.TicketUrl === ticketLink))}
              onSelect={(i) => setTicketLink(i.key.toString())}
              items={event.TicketList.map(ticketListItemToSelectItem)} />}
          </Layout.VerticalFlex>
          <Layout.VerticalFlex>
            {ticketLink && ticketLink.length > 0 && <button onClick={() => window.open(ticketLink, "_blank")}>Åben link</button>}
          </Layout.VerticalFlex>
        </Layout.HorizontalFlex>
        <Spacer />
        <Layout.HorizontalFlex style={{ justifyContent: 'space-between' }}>
          <Layout.VerticalFlex style={{ paddingRight: 12 }}>
            <InputLabel>Rabatkode (valgfri)</InputLabel>
            <input onChange={(v) => setDiscountCode(v.target.value)} />
          </Layout.VerticalFlex>
          <Layout.VerticalFlex>
            {discountCode && discountCode.length > 0 && <span style={{ padding: "6px 12px", backgroundColor: colors.white, color: colors.main, borderRadius: 30 }}>{discountCode}</span>}
          </Layout.VerticalFlex>
        </Layout.HorizontalFlex>
        <Spacer />
        <Layout.VerticalFlex style={{ paddingRight: 12, paddingTop: 15 }}>
          <button onClick={createTicket} disabled={!validProduct}>{step === 1 ? "Opret Last Chance rabat" : "Gem ændringer"}</button>
        </Layout.VerticalFlex>
      </Layout.VerticalFlex>
    </Layout.VerticalFlex>
  )

  return (
    <Layout.VerticalFlex id='lastchance' style={{ marginTop: 32, marginBottom: 32 }}>
      <Stepper steps={["Vælg event", "Indtast detaljer", "Indsend billetinformationer"]} activeIndex={step} />
      {!loading && step === 0 && (<Layout.HorizontalFlexAlignCenter onClick={() => setStep(1)} style={{
        justifyContent: 'center', height: 100,
        borderRadius: 20, backgroundColor: colors.primary, marginTop: 12, cursor: 'pointer'
      }}>
        <b style={{ color: colors.white }}>Påbegynd last chance</b>
      </Layout.HorizontalFlexAlignCenter>)}
      {!loading && step === 2 && (<Layout.HorizontalFlexAlignCenter style={{
        justifyContent: 'center', height: 100,
        borderRadius: 20,  marginTop: 12,
      }}>
        <Layout.VerticalFlex>
        <b style={{ color: colors.white, marginBottom: 12 }}>Tillykke, dit last chance event er oprettet</b>
        <button onClick={()=>setStep(3)}>Rediger last chance event</button>
        </Layout.VerticalFlex>
      </Layout.HorizontalFlexAlignCenter>)}
      {!loading && (step === 1 || step === 3) && detailsStep}
      {loading && <Layout.HorizontalFlexJustifyCenter style={{ marginTop: 100, marginRight: 12 }}><LoadingSpinner /></Layout.HorizontalFlexJustifyCenter>}
    </Layout.VerticalFlex>)
}


export const DetailedEvent: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>()
  const [event, setEvent] = React.useState<KultunautDetailedEvent>()
  const [venue, setVenue] = React.useState<KultunautVenue>()

  const [loading, setLoading] = React.useState<LoadingState>()

  const init = React.useCallback(async (disableLoading?: boolean) => {
    if (!disableLoading) setLoading({ loading: Loading.LOADING })
    const result = await apiHooksV2.getEventDashboard(Number(eventId))
    if (result) {
      setVenue(result.venue)
      setEvent(result.event)
    } else {
      if (!disableLoading) setLoading({ loading: Loading.ERROR, errorMessage: "Kunne ikke finde organisationen med id: " + eventId })
    }
    if (!disableLoading) setLoading({ loading: Loading.COMPLETE })

  }, [eventId])


  React.useEffect(() => { init() }, [init])


  if (!loading || loading.loading === Loading.LOADING) return <LoadingScreen />
  if (loading.loading === Loading.ERROR) return <p>Error: {loading.errorMessage}</p>
  if (!venue || !event) return <p>Error: Cannot find requested event. Please contact the IT Administrator</p>


  return (
    <Layout.Body>
      <Navigation title={venue.Name} />
      <Layout.Container style={{ display: "flex", flexDirection: "row" }}>
        <Layout.VerticalFlex style={{ minWidth: 300 }}>
          <Layout.VerticalFlex style={{ marginBottom: 14 }}>
            <h4>Genveje</h4>
            <button className='secondary' style={{ marginTop: 16 }} onClick={() => window.location.href = 'https://www.gogoo.app/kontakt'}>Support</button>
            <button className='secondary' style={{ marginTop: 16 }} onClick={() => window.location.href = '#lastchance'}>Last chance</button>
          </Layout.VerticalFlex>
          <Layout.VerticalFlex style={{ marginBottom: 14 }}>
            <h4>Udbyder</h4>
            <b>{venue.Name}</b>
            <span>{venue.Address}, {venue.Zip} {venue.City}</span>
            <span>{venue.Phone && "Tel: "}{venue.Phone}</span>
            <button className='secondary' style={{ marginTop: 16 }} onClick={() => window.location.href = ('/venues/' + venue.Id)}>Gå til udbyderen</button>
          </Layout.VerticalFlex>
        </Layout.VerticalFlex>
        <Layout.VerticalFlex style={{ width: '100%' }}>
          <Layout.HorizontalFlex style={{ justifyContent: 'space-between', marginLeft: 14, marginRight: 14, alignItems: 'center' }}>
            <h1>{event?.Title}</h1>
            <StatusComponent active={true} />
          </Layout.HorizontalFlex>
          <Layout.VerticalFlex style={{ flex: 1 }}>
            <Layout.HorizontalFlex style={{ justifyContent: 'space-between', width: '100%' }}>
              <Layout.VerticalFlex style={{ margin: 14 }}>
                <span><b>Tidspunkt:</b> {event.Startdate} {event.Starttime && ("Kl: " + event.Starttime)}</span>
                {event.Enddate !== event.Startdate && <span><b>Til:</b> {event.Enddate}</span>}
                <b>Kort beskrivelse <Tooltip description='Denne information kommer fra Kultunaut.dk og kan kun redigeres via Kultunaut.dk' /></b>
                {event.Shortdescription ? <p>{event.Shortdescription}</p> : <i>Intet indhold</i>}
                <b>Lang beskrivelse <Tooltip description='Denne information kommer fra Kultunaut.dk og kan kun redigeres via Kultunaut.dk' /></b>
                {event.Longdescription ? <div dangerouslySetInnerHTML={{ "__html": event.Longdescription }}></div> : <i>Intet indhold</i>}
                <Layout.VerticalFlex>
                  <h2 style={{ textAlign: 'center' }}>Last Chance billetter</h2>
                  <p style={{ textAlign: 'center' }}>Her kan du tilføje og ændre last-chance billetter for <b>{event.Title}</b>, så de bliver tilgængelige på gogo's platform</p>
                  <LastChanceComponent event={event} />
                </Layout.VerticalFlex>
                <b>Billetter</b>
                {(!event.TicketList || event.TicketList.length === 0) && <i>Ingen billetter tilføjet</i>}
                {event.TicketList && <CustomTable data={[["Title", "Tidspunkt", "Pris", "Leverandør", "Link"], ...(event.TicketList).map((item) => [
                  item.TicketTitle,
                  ((item.TicketStartDate !== item.TicketEndDate ? item.TicketStartDate + " - " + item.TicketEndDate : item.TicketStartDate) + " " + item.TicketTime),
                  item.TicketPrice,
                  item.TicketVendor,
                  <button onClick={() => window.open(item.TicketUrl, "_blank")}>Åben</button>
                ])]} />}              </Layout.VerticalFlex>
              <Layout.VerticalFlex style={{ margin: 14 }}>
                <a target='_blank' rel="noreferrer" href={'https://www.kultunaut.dk/perl/addcomment/type-nynaut?ArrNr=' + eventId}>Rediger på Kultunaut.dk</a>
              </Layout.VerticalFlex>
            </Layout.HorizontalFlex>
          </Layout.VerticalFlex>
        </Layout.VerticalFlex>
      </Layout.Container>
    </Layout.Body>
  )
}